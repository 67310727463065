<template>
  <div>
    <div class="ant-glass-background my-2">
      <dynamic-data-table
        table-title="Modules"
        :table-headers="moduleHeaders"
        :table-records="tableRecords"
        :items-per-page="10"
        :clickable-rows="true"
        :has-options="true"
        :export-file-name="`wvb_modules`"
        @clickedRow="displayModule"
      >
        <template #item.module_id="{ value, rowId, item }">
          <td :class="{ selected: isModuleSelected(rowId) }">
            {{ value }}
          </td>
        </template>
        <template
          v-for="header in moduleHeaders.filter((tmp) => tmp.editable)"
          #[`item.${header.value}`]="{ value, rowId, item }"
        >
          <div :key="header.value" class="editable-field">
            <v-edit-dialog
              :return-value.sync="item[header.value]"
              large
              @save="save(item.id, header.value, item[header.value])"
            >
              <div class="editable-field">
                {{ item[header.value] }}
                <v-icon class="edit-icon" small> mdi-pencil</v-icon>
              </div>
              <template #input>
                <v-text-field
                  v-model="item[header.value]"
                  label="Edit"
                  single-line
                />
              </template>
            </v-edit-dialog>
          </div>
        </template>
        <template #item.placement_sequence="{ value, rowId, item }">
          {{
            value
              ? `${wvbPhases.find((phase) => phase.id === item.phase).number}.`
              : undefined
          }}{{ value }}
        </template>
        <template #item.assembly_sequence="{ value, rowId, item }">
          <td>
            <div class="d-flex">
              <v-edit-dialog
                :return-value.sync="item.assembly_sequence"
                large
                @save="updateOrder(rowId, editedSequenceItem.assembly_sequence)"
                @open="editedSequenceItem = Object.assign({}, item)"
                @close="editedSequenceItem = Object.assign({}, {})"
              >
                {{ value }}
                <template #input>
                  <v-text-field
                    v-model="editedSequenceItem.assembly_sequence"
                    label="Edit"
                    single-line
                    type="number"
                  />
                </template>
              </v-edit-dialog>
              <v-tooltip v-if="item.assembly_sequence" left>
                <template #activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    class="ant-icon mx-1"
                    small
                    v-on="on"
                    @click.stop="switchOrderUp(item, 'assembly_sequence')"
                  >
                    mdi-arrow-up
                  </v-icon>
                </template>
                <span>{{ $t('modules.wvb.switchUp') }}</span>
              </v-tooltip>
              <v-tooltip v-if="item.assembly_sequence" right>
                <template #activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    small
                    class="ant-icon mx-1"
                    v-on="on"
                    @click.stop="switchOrderDown(item, 'assembly_sequence')"
                  >
                    mdi-arrow-down
                  </v-icon>
                </template>
                <span>{{ $t('modules.wvb.switchDown') }}</span>
              </v-tooltip>
            </div>
          </td>
        </template>
        <template #item.phase="{ value, rowId, item }">
          <div>
            {{
              value
                ? wvbPhases.find((phase) => phase.id === value).name
                : undefined
            }}
          </div>
        </template>
        <template #table-actions>
          <v-tooltip v-if="sortedModules.length > 0" bottom>
            <template #activator="{ on, attrs }">
              <v-icon
                class="ml-2 ant-icon"
                @click="undoHighestAction"
                v-on="on"
              >
                mdi-undo</v-icon
              >
            </template>
            <span>{{
              $t('modules.wvb.undoAS', {
                moduleId: sortedModules[sortedModules.length - 1].module_id,
              })
            }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-icon
                :color="moduleSwitchToggle ? 'primary' : ''"
                class="ant-icon ml-2"
                @click="toggleModuleSwitch"
                v-on="on"
              >
                mdi-repeat
              </v-icon>
            </template>
            <span>{{ $t('modules.wvb.switchModuleWithinModel') }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-icon
                :color="playerToggle ? 'primary' : ''"
                :disabled="!canDisplayPlayer"
                class="ant-icon ml-2"
                @click="togglePlayer"
                v-on="on"
              >
                mdi-motion-play-outline
              </v-icon>
            </template>
            <span>{{ $t('modules.wvb.playerToggle') }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-icon
                :color="colorPhasesToggle ? 'primary' : ''"
                class="ant-icon ml-2"
                @click="colorPhases()"
                v-on="on"
              >
                mdi-format-color-highlight
              </v-icon>
            </template>
            <span>{{ $t('modules.wvb.colorPhases') }}</span>
          </v-tooltip>
          <v-tooltip v-if="tableRecords.length > 0" bottom>
            <template #activator="{ on, attrs }">
              <v-icon
                :color="colorOrderToggle ? 'primary' : ''"
                class="ant-icon ml-2"
                v-on="on"
                @click="renderAssemblySequence"
              >
                mdi-palette
              </v-icon>
            </template>
            <span>{{
              $t('modules.wvb.colorGradient', { mode: configureColumn })
            }}</span>
          </v-tooltip>
          <v-tooltip v-if="tableRecords.length > 0" bottom>
            <template #activator="{ on, attrs }">
              <v-icon
                :color="assemblySequenceConfigureToggle ? 'primary' : ''"
                class="ant-icon ml-2"
                v-on="on"
                @click="toggleConfigure"
                >mdi-cog-transfer
              </v-icon>
            </template>
            <span>{{
              $t('modules.wvb.configure', { mode: configureColumn })
            }}</span>
          </v-tooltip>
        </template>

        <template #table-options-menu>
          <v-list-item @click="rebaseSequence()">
            <v-list-item-icon class="mr-2">
              <v-icon dense> mdi-format-list-numbered</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ $t('modules.wvb.rebaseAssemblySequence') }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="resetAssemblySequenceDialog = true">
            <v-list-item-icon class="mr-2">
              <v-icon dense> mdi-cog-transfer</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ $t('modules.wvb.clearAssemblySequence') }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="exportToExcel">
            <v-list-item-icon style="margin-right: 10px">
              <v-icon dense> mdi-export</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ $t('modules.wvb.exportExcel') }}
            </v-list-item-title>
          </v-list-item>
        </template>

        <template #table-row-actions="{ item }">
          <td style="display: flex">
            <v-tooltip left>
              <template #activator="{ on, attrs }">
                <v-icon
                  small
                  v-bind="attrs"
                  class="ant-icon mx-1"
                  v-on="on"
                  @click.stop="
                    wvbInsert.dialog = true;
                    wvbInsert.module = item;
                  "
                >
                  mdi-arrow-collapse-right
                </v-icon>
              </template>
              <span>{{ $t('modules.wvb.insert') }}</span>
            </v-tooltip>
            <v-tooltip left>
              <template #activator="{ on, attrs }">
                <v-icon
                  small
                  class="ant-icon mx-1"
                  v-bind="attrs"
                  v-on="on"
                  @click.stop="
                    wvbSwitch.dialog = true;
                    wvbSwitch.module = item;
                  "
                >
                  mdi-repeat
                </v-icon>
              </template>
              <span>{{ $t('modules.wvb.switchModule') }}</span>
            </v-tooltip>
          </td>
        </template>
      </dynamic-data-table>
    </div>
    <w-v-b-insert-dialog
      v-if="wvbInsert.module"
      :dialog="wvbInsert.dialog"
      :module="wvbInsert.module"
      @closeDialog="wvbInsert = { dialog: false, module: undefined }"
      @insertModule="insertModuleOnPosition"
    />
    <w-v-b-switch-dialog
      v-if="wvbSwitch.module"
      :dialog="wvbSwitch.dialog"
      :module="wvbSwitch.module"
      @closeDialog="wvbSwitch = { dialog: false, module: undefined }"
      @switchModule="switchModuleWithPosition"
    />
    <!-- TODO FIX translation -->
    <delete-dialog
      :dialog="resetAssemblySequenceDialog"
      :title="$t('modules.wvb.notifications.confirmResetAS')"
      @closeDialog="closeDialogs"
      @deleteAction="resetAssemblySequence"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DynamicDataTable from '@/components/DynamicDataTable';
import DeleteDialog from '@/components/DeleteDialog';
import WVBInsertDialog from '@/components/Modules/Daiwa-House-Modular-Europe/WVB/DHME.WVB.InsertDialog';
import WVBSwitchDialog from '@/components/Modules/Daiwa-House-Modular-Europe/WVB/DHME.WVB.SwitchDialog';
import { executeAutomation } from '@/services/api/automation.api';
import moment from 'moment';
import {
  getObjectsWithMappingLevel,
  hexToVector4,
  pSBC,
} from '@/components/Modules/Daiwa-House-Modular-Europe/utils/DHME+utils';

export default {
  name: 'WVBAssemblySidebar',
  components: {
    WVBSwitchDialog,
    WVBInsertDialog,
    DeleteDialog,
    DynamicDataTable,
  },
  data: () => {
    return {
      moduleHeaders: [
        {
          text: 'module',
          value: 'module_id',
          hasSlot: true,
        },
        {
          text: 'type',
          value: 'module_type',
          editable: true,
          hasSlot: true,
        },
        {
          text: 'placement sequence',
          value: 'placement_sequence',
          type: 'order',
          hasSlot: true,
        },
        {
          text: 'assembly sequence',
          value: 'assembly_sequence',
          type: 'order',
          hasSlot: true,
        },
        {
          text: 'assembly workload (hours)',
          value: 'assembly_workload',
          editable: true,
          hasSlot: true,
        },
        {
          text: 'phase',
          value: 'phase',
          hasSlot: true,
        },
        { text: 'Actions', value: 'actions', align: 'right', sortable: false },
      ],

      configureColumn: 'assembly_sequence',
      editedSequenceItem: {},
      optionsMenu: false,
      assemblySequenceConfigureToggle: false,
      resetAssemblySequenceDialog: false,
      colorPhasesToggle: false,
      colorOrderToggle: false,
      playerToggle: false,
      moduleSwitchToggle: false,
      wvbInsert: {
        dialog: false,
        module: undefined,
      },
      wvbSwitch: {
        dialog: false,
        module: undefined,
      },
    };
  },
  computed: {
    ...mapGetters([
      'project',
      'wvbData',
      'wvbOrderColumn',
      'wvbPhases',
      'wvbModules',
      'wvbNonModules',
      'wvbHighlightedModule',
      'wvbModelMapping',
    ]),
    tableRecords() {
      return this.wvbModules;
    },
    sortedPhases() {
      return [...this.wvbPhases].sort((a, b) => a.name.localeCompare(b.name));
    },
    canDisplayPlayer() {
      return (
        this.tableRecords.filter((item) => item.assembly_sequence).length > 0
      );
    },
    sortedModules() {
      return [...this.tableRecords]
        .filter((record) => record.assembly_sequence)
        .sort(function (a, b) {
          return a.assembly_sequence - b.assembly_sequence;
        });
    },
    forgeViewer() {
      return this.$parent.$parent.$refs['wvb-viewer'].$refs['forge-viewer']
        .viewerService.Viewer3D;
    },
    forgeViewerService() {
      return this.$parent.$parent.$refs['wvb-viewer'].$refs['forge-viewer']
        .viewerService;
    },
    assemblyModelObjects() {
      return this.$parent.$parent.$refs['wvb-viewer'].modelObjects;
    },
  },
  watch: {
    wvbHighlightedModule(value) {
      this.$parent.$parent.$refs['wvb-viewer'].selectedModule =
        this.wvbModules.find((item) => item.module_id === value);
    },
  },
  mounted() {
    this.$store.dispatch('setOrderColumn', this.configureColumn);

    let sorted = [...this.tableRecords]
      .map((item) => item.assembly_sequence)
      .sort(function (a, b) {
        return a - b;
      });

    if (sorted.length > 0) {
      this.$store.commit('set_highest_sequence', sorted[sorted.length - 1]);
    } else {
      this.$store.commit('set_highest_sequence', 0);
    }
  },
  methods: {
    exportToExcel() {
      let data = {
        modules: [],
        projectData: {
          name: this.project.name,
          number: this.project.number,
        },
      };
      data.modules = [...this.tableRecords].sort(
        (a, b) => a.placement_sequence - b.placement_sequence
      );

      executeAutomation('9ef53931-fc5c-11ec-9411-00ff47ce35fe', data).then(
        (response) => {
          const element = document.createElement('a');
          element.setAttribute('href', `data:xlsx;base64,${response.file}`);
          element.setAttribute(
            'download',
            `bouwvolgorde_${moment().format('YYYY-MM-DD')}.xlsx`
          );

          element.style.display = 'none';
          document.body.appendChild(element);

          element.click();

          document.body.removeChild(element);
        }
      );
    },
    rebaseSequence() {
      let records = [...this.sortedModules].filter(
        (record) => record.assembly_sequence !== null
      );

      records.map((record, index) => {
        record.assembly_sequence = index + 1;
        return record;
      });

      let data = records.map(({ id, module_id, assembly_sequence }) => ({
        id,
        module_id,
        assembly_sequence,
      }));

      this.$store.dispatch('importWvbModules', data).then(() => {
        this.$store.commit('showNotification', {
          color: 'success',
          content: this.$t('modules.wvb.notifications.rebaseAssemblySequence'),
        });
      });
    },
    switchModuleWithPosition(position) {
      let records = this.tableRecords.map((x) => {
        return { ...x };
      });

      let switchModule = records.find(
        (module) => module.assembly_sequence === position
      );

      if (switchModule !== undefined) {
        let module = Object.assign({}, this.wvbSwitch.module);
        switchModule.assembly_sequence = module.assembly_sequence;
        module.assembly_sequence = position;
        let modules = [];

        modules.push(module);
        modules.push(switchModule);

        modules.forEach((module) => delete module.session);

        this.$store.dispatch('importWvbModules', modules).then(() => {
          this.wvbSwitch = {
            dialog: false,
            module: undefined,
          };
          this.$store.commit('showNotification', {
            content: `Module: ${module.module_id} switched to position ${position}`,
            color: 'success',
          });
        });
      } else {
        let module = Object.assign({}, this.wvbSwitch.module);
        this.$store
          .dispatch('updateWVBSequence', {
            recordId: module.id,
            column: 'placement_sequence',
            updatedSequence: position,
          })
          .then(() => {
            this.wvbSwitch = {
              dialog: false,
              module: undefined,
            };
            this.$store.commit('showNotification', {
              content: this.$t(
                'modules.wvb.notifications.moduleSwitchedPosition',
                {
                  module_id: module.module_id,
                  position: position,
                }
              ),
              color: 'success',
            });
          });
      }
    },
    insertModuleOnPosition(position) {
      let records = this.tableRecords.map((x) => {
        return { ...x };
      });

      if (
        records.find((module) => module.assembly_sequence === position) !==
        undefined
      ) {
        let sorted = records.sort((a, b) => {
          return a.assembly_sequence - b.assembly_sequence;
        });

        let modulesAfterNewPosition;

        if (this.wvbInsert.module.assembly_sequence > position) {
          modulesAfterNewPosition = sorted.filter(
            (module) => module.assembly_sequence >= position
          );
        } else {
          modulesAfterNewPosition = sorted.filter(
            (module) =>
              module.assembly_sequence >= position ||
              module.assembly_sequence < this.wvbInsert.assembly_sequence
          );
        }

        modulesAfterNewPosition.forEach((module) => {
          module.assembly_sequence += 1;
        });

        let module = Object.assign({}, this.wvbInsert.module);
        module.assembly_sequence = position;

        modulesAfterNewPosition.push(module);
        this.wvbInsert.module.assembly_sequence = position;

        modulesAfterNewPosition.forEach((module) => delete module.session);

        this.$store
          .dispatch('importWvbModules', modulesAfterNewPosition)
          .then(() => {
            this.wvbInsert = {
              dialog: false,
              module: undefined,
            };
            this.$store.commit('showNotification', {
              content: this.$t(
                'modules.wvb.notifications.moduleInsertedPosition',
                {
                  module_id: module.module_id,
                  position: position,
                }
              ),
              color: 'success',
            });
          });
      } else {
        let module = Object.assign({}, this.wvbInsert.module);
        this.$store
          .dispatch('updateWVBSequence', {
            recordId: module.id,
            column: 'placement_sequence',
            updatedSequence: position,
          })
          .then(() => {
            this.wvbInsert = {
              dialog: false,
              module: undefined,
            };
            this.$store.commit('showNotification', {
              content: this.$t(
                'modules.wvb.notifications.moduleInsertedPosition',
                {
                  module_id: module.module_id,
                  position: position,
                }
              ),
              color: 'success',
            });
          });
      }
    },
    toggleModuleSwitch() {
      this.moduleSwitchToggle = !this.moduleSwitchToggle;

      if (this.moduleSwitchToggle) {
        // setup modulesSwitch
        this.placementSequenceConfigureToggle = false;
        this.$parent.$parent.$refs[
          'wvb-viewer'
        ].unloadEventAndEnableDefaultSelectionEvent();
        this.$parent.$parent.$refs['wvb-viewer'].configurationMode = true;
        this.$parent.$parent.$refs['wvb-viewer'].configurationMessage =
          `Switch modules`;
        this.$parent.$parent.$refs[
          'wvb-viewer'
        ].loadSelectionEventSwitchModules();
        document.addEventListener('keydown', this.closeSwitchMode);
      } else {
        // remove modulesSwitch event
        this.$parent.$parent.$refs[
          'wvb-viewer'
        ].unloadEventAndEnableDefaultSelectionEvent();
        this.$parent.$parent.$refs['wvb-viewer'].configurationMode = false;
        document.removeEventListener('keydown', this.closeSwitchMode);
      }
      this.$nextTick(() => {
        this.forgeViewerService.resizeView();
      });
    },
    closeSwitchMode(event) {
      if (event.key === 'Escape') {
        //do something
        this.moduleSwitchToggle = false;
        this.$parent.$parent.$refs[
          'wvb-viewer'
        ].unloadEventAndEnableDefaultSelectionEvent();
        this.$parent.$parent.$refs['wvb-viewer'].configurationMode = false;
        document.removeEventListener('keydown', this.closeSwitchMode);
      }
    },
    isModuleSelected(id) {
      return (
        this.$parent.$parent.$refs['wvb-viewer']?.selectedModule?.id === id
      );
    },
    undoHighestAction() {
      let module = this.sortedModules[this.sortedModules.length - 1];

      this.$store
        .dispatch('updateWVBSequence', {
          recordId: module.id,
          column: 'assembly_sequence',
          updatedSequence: null,
        })
        .then(() => {
          if (this.sortedModules.length > 0) {
            this.$store.commit(
              'set_highest_sequence',
              this.sortedModules[this.sortedModules.length - 1]
            );
          } else {
            this.$store.commit('set_highest_sequence', 0);
          }
        });
    },
    resetView() {
      this.$nextTick(() => {
        this.forgeViewer.showAll();
      });
    },
    togglePlayer() {
      this.playerToggle = !this.playerToggle;
      this.$parent.$parent.$refs['wvb-viewer'].playerDisplayed =
        this.playerToggle;
      this.$parent.$parent.$refs[
        'wvb-viewer'
      ].unloadEventAndEnableDefaultSelectionEvent();
      this.resetView();

      if (this.assemblySequenceConfigureToggle) {
        this.toggleConfigure();
      }
      if (this.moduleSwitchToggle) {
        this.toggleModuleSwitch();
      }

      if (this.playerToggle) {
        this.$nextTick(() => {
          let sorted = [...this.tableRecords]
            .filter((item) => item.assembly_sequence)
            .map((item) => item.assembly_sequence)
            .sort(function (a, b) {
              return a - b;
            });
          this.$parent.$parent.$refs['wvb-viewer'].$refs[
            'wvb-player'
          ].maxPlaySequence = sorted[sorted.length - 1];
          this.$parent.$parent.$refs['wvb-viewer'].$refs['wvb-player'].modules =
            [...this.tableRecords];
          this.$parent.$parent.$refs['wvb-viewer'].$refs['wvb-player'].column =
            'assembly_sequence';
        });
      } else {
        this.forgeViewer.clearSelection();
      }
    },
    toggleConfigure() {
      this.assemblySequenceConfigureToggle =
        !this.assemblySequenceConfigureToggle;

      if (this.playerToggle) {
        this.togglePlayer();
      }
      if (this.moduleSwitchToggle) {
        this.toggleModuleSwitch();
      }

      if (this.assemblySequenceConfigureToggle) {
        this.$parent.$parent.$refs['wvb-viewer'].configurationMode = true;
        this.$parent.$parent.$refs['wvb-viewer'].configurationMessage =
          `Configuring assembly sequence`;
        this.forgeViewer.clearSelection();
        this.$store.dispatch('setOrderColumn', this.configureColumn);
        this.$parent.$parent.$refs['wvb-viewer'].loadSelectionEventOrder();
        this.renderAssemblySequence();
        document.addEventListener('keydown', this.closeConfigureAssemblyMode);
      } else {
        this.$parent.$parent.$refs['wvb-viewer'].configurationMode = false;
        this.$store.dispatch('resetOrderColumn');
        this.$parent.$parent.$refs[
          'wvb-viewer'
        ].unloadEventAndEnableDefaultSelectionEvent();
        document.removeEventListener(
          'keydown',
          this.closeConfigureAssemblyMode
        );
      }
    },
    closeConfigureAssemblyMode(event) {
      if (event.key === 'Escape') {
        //do something
        this.assemblySequenceConfigureToggle = false;
        this.$parent.$parent.$refs['wvb-viewer'].configurationMode = false;
        this.$store.dispatch('resetOrderColumn');
        this.$parent.$parent.$refs[
          'wvb-viewer'
        ].unloadEventAndEnableDefaultSelectionEvent();
        document.removeEventListener(
          'keydown',
          this.closeConfigureAssemblyMode
        );
      }
    },
    closeDialogs() {
      this.resetAssemblySequenceDialog = false;
    },
    resetAssemblySequence() {
      let records = [...this.tableRecords].filter(
        (record) => record.assembly_sequence !== null
      );

      records.map((record) => {
        record.assembly_sequence = null;
        return record;
      });

      let data = records.map(({ id, module_id, assembly_sequence }) => ({
        id,
        module_id,
        assembly_sequence,
      }));

      this.$store.dispatch('importWvbModules', data).then(() => {
        this.$store.commit('showNotification', {
          color: 'success',
          content: this.$t('modules.wvb.notifications.productionOrderReset'),
        });
        this.closeDialogs();
        this.$store.commit('set_highest_sequence', 0);
        this.forgeViewer.clearThemingColors();
      });
    },
    colorPhases() {
      this.colorPhasesToggle = !this.colorPhasesToggle;
      this.colorOrderToggle = false;
      this.$parent.$parent.$refs['wvb-viewer'].colorTypesToggle = false;

      if (this.colorPhasesToggle) {
        const moduleIdMappingLevels = this.wvbModelMapping.module_id.split('.');

        this.forgeViewer.clearThemingColors();

        this.sortedPhases.forEach((phase) => {
          let modulesInPhase = this.wvbModules.filter(
            (record) => record.phase === phase.id
          );
          let nonModulesInPhase = this.wvbNonModules.filter(
            (record) => record.phase === phase.id
          );

          let moduleObjects = getObjectsWithMappingLevel(
            this.assemblyModelObjects,
            moduleIdMappingLevels
          ).filter(
            (object) =>
              modulesInPhase.findIndex(
                (module) =>
                  moduleIdMappingLevels.reduce(
                    (o, i) => o[i],
                    object.properties
                  ) === module.module_id
              ) !== -1
          );
          let nonModuleObjects = getObjectsWithMappingLevel(
            this.assemblyModelObjects,
            moduleIdMappingLevels
          ).filter(
            (object) =>
              nonModulesInPhase.findIndex(
                (module) =>
                  moduleIdMappingLevels.reduce(
                    (o, i) => o[i],
                    object.properties
                  ) === module.non_module_id
              ) !== -1
          );
          let objects = [...moduleObjects, ...nonModuleObjects];

          let color = hexToVector4(phase.color);

          let objectIds = this.$parent.$parent.$refs['wvb-viewer'].$refs[
            'forge-viewer'
          ].mapExternalIdsToObjectIds(
            objects.map((object) => object.externalId)
          );
          objectIds.forEach((id) => {
            this.forgeViewer.setThemingColor(id, color, null, true);
          });
        });
      } else {
        this.forgeViewer.clearThemingColors();
      }
    },
    save(recordId, column, value) {
      let body = {
        project: {
          id: this.project.id,
        },
        table: {
          id: this.wvbData.CFFA_DHME_MODULES.id,
        },
        record: {},
      };

      body.record[column] = value;

      this.$store.dispatch('updateWVBRecord', { recordId: recordId, body });
    },
    renderAssemblySequence() {
      this.colorOrderToggle = !this.colorOrderToggle;
      this.colorPhasesToggle = false;
      this.$parent.$parent.$refs['wvb-viewer'].colorTypesToggle = false;

      if (this.colorOrderToggle) {
        this.forgeViewer.clearThemingColors();

        const moduleIdMappingLevels = this.wvbModelMapping.module_id.split('.');
        let objectsWithMapping = getObjectsWithMappingLevel(
          this.assemblyModelObjects,
          moduleIdMappingLevels
        );

        // color production order
        let sortedProductionOrder = this.wvbModules
          .filter(
            (module) =>
              module.assembly_sequence !== null &&
              module.assembly_sequence !== undefined
          )
          .sort(function (a, b) {
            return a.assembly_sequence - b.assembly_sequence;
          });

        sortedProductionOrder.reverse().forEach((module, index) => {
          let objects = objectsWithMapping.filter(
            (object) =>
              moduleIdMappingLevels.reduce(
                (o, i) => o[i],
                object.properties
              ) === module.module_id
          );
          let percent =
            (Math.floor((index / sortedProductionOrder.length) * 200) - 95) /
            100;
          let colorHex = pSBC(
            percent,
            this.$vuetify.theme.themes.light.primary
          );

          let color = hexToVector4(colorHex);

          let objectIds = this.$parent.$parent.$refs['wvb-viewer'].$refs[
            'forge-viewer'
          ].mapExternalIdsToObjectIds(
            objects.map((object) => object.externalId)
          );

          objectIds.forEach((id) => {
            this.forgeViewer.setThemingColor(id, color, null, true);
          });
        });
      } else {
        this.forgeViewer.clearThemingColors();
      }
    },
    switchOrderUp(item) {
      let switchItem = undefined;
      switchItem = this.wvbModules.find(
        (build) =>
          build.assembly_sequence === parseInt(item.assembly_sequence) + 1
      );

      if (switchItem !== undefined) {
        this.$store.dispatch('updateWVBSequence', {
          recordId: switchItem.id,
          column: 'assembly_sequence',
          updatedSequence: parseInt(item.assembly_sequence),
        });
      }
      this.$store.dispatch('updateWVBSequence', {
        recordId: item.id,
        column: 'assembly_sequence',
        updatedSequence: parseInt(item.assembly_sequence) + 1,
      });

      this.$store.commit('showNotification', {
        content: this.$t('modules.wvb.notifications.moduleSetOnPosition', {
          module_id: item.module_id,
          position: parseInt(item.assembly_sequence) + 1,
        }),
        color: 'success',
      });
    },
    switchOrderDown(item) {
      if (parseInt(item.assembly_sequence) - 1 > 0) {
        let switchItem = undefined;
        switchItem = this.wvbModules.find(
          (build) =>
            build.assembly_sequence === parseInt(item.assembly_sequence) - 1
        );

        if (switchItem !== undefined) {
          this.$store
            .dispatch('updateWVBSequence', {
              recordId: switchItem.id,
              column: 'assembly_sequence',
              updatedSequence: parseInt(item.assembly_sequence),
            })
            .then((response) => {});
        }
        this.$store
          .dispatch('updateWVBSequence', {
            recordId: item.id,
            column: 'assembly_sequence',
            updatedSequence: parseInt(item.assembly_sequence) - 1,
          })
          .then((response) => {});

        this.$store.commit('showNotification', {
          content: this.$t('modules.wvb.notifications.moduleSetOnPosition', {
            module_id: item.module_id,
            position: parseInt(item.assembly_sequence) - 1,
          }),
          color: 'success',
        });
      } else {
        this.$store.commit('showNotification', {
          content: this.$t('modules.wvb.cannotBeLower'),
          color: 'error',
        });
      }
    },
    updateOrder(id, sequence) {
      this.$store.dispatch('updateWVBSequence', {
        recordId: id,
        column: 'assembly_sequence',
        updatedSequence: parseInt(sequence),
      });
    },
    displayModule(module) {
      if (this.$parent.$parent.$refs['wvb-viewer'].selectedModule !== module) {
        this.$parent.$parent.$refs['wvb-viewer'].selectedModule = module;

        const moduleIdMappingLevels = this.wvbModelMapping.module_id.split('.');
        const objects = getObjectsWithMappingLevel(
          this.assemblyModelObjects,
          moduleIdMappingLevels
        ).filter(
          (object) =>
            moduleIdMappingLevels.reduce((o, i) => o[i], object.properties) ===
            module.module_id
        );

        let objectIds = this.$parent.$parent.$refs['wvb-viewer'].$refs[
          'forge-viewer'
        ].mapExternalIdsToObjectIds(objects.map((object) => object.externalId));

        this.forgeViewer.select(objectIds);
      } else {
        this.$parent.$parent.$refs['wvb-viewer'].clearSearch();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.editable-field {
  display: flex;

  .edit-icon {
    opacity: 0;
    transition: 200ms;
  }

  &:hover {
    .edit-icon {
      opacity: 1;
    }
  }
}

.selected {
  border-left: solid 5px var(--v-primary-base);
}
</style>
