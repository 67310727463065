<template>
  <div
    v-if="maxPlaySequence > 0 && modules.length > 0 && column"
    class="wvb-player ant-glass-background"
  >
    <div class="player-slider">
      <v-btn
        class="play-button mr-5"
        color="primary"
        dark
        x-small
        depressed
        fab
        @click="isSequencePlaying = !isSequencePlaying"
      >
        <v-icon dense>
          {{ isSequencePlaying ? 'mdi-pause' : 'mdi-play' }}
        </v-icon>
      </v-btn>
      <v-slider
        v-model="playSequenceSlider"
        step="1"
        style="width: 200px"
        :max="maxPlaySequence"
        hide-details
        @change="renderStaticPlaySequence"
      />
      {{ playSequenceSlider }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getObjectsWithMappingLevel } from '@/components/Modules/Daiwa-House-Modular-Europe/utils/DHME+utils';

export default {
  name: 'WVBPlayer',
  data: () => {
    return {
      isSequencePlaying: false,
      playSequenceSlider: 0,
      maxPlaySequence: undefined,
      modules: [],
      column: undefined,
    };
  },
  computed: {
    ...mapGetters(['wvbModelMapping']),

    forgeViewer() {
      return this.$parent.$parent.$refs['forge-viewer'].viewerService.Viewer3D;
    },
    playerModelObjects() {
      return this.$parent.$parent.modelObjects;
    },
  },
  watch: {
    isSequencePlaying(state) {
      if (
        this.playSequenceSlider === this.maxPlaySequence &&
        !this.isSequencePlaying
      ) {
        this.playSequenceSlider = 0;
      }
      this.playOrder();
    },
  },
  methods: {
    renderStaticPlaySequence() {
      if (this.playSequenceSlider !== 0) {
        this.renderData();
      }
    },
    playOrder() {
      if (
        this.isSequencePlaying &&
        this.playSequenceSlider < this.maxPlaySequence
      ) {
        this.renderData();
        let that = this;
        setTimeout(function () {
          that.playOrder();
        }, 500);
      } else {
        this.isSequencePlaying = false;
      }
    },
    renderData() {
      let modules = this.modules.filter((record) => record[this.column]);
      this.playSequenceSlider += 1; //was 10
      let renderData = {
        previous: modules.filter(
          (item) => item[this.column] < this.playSequenceSlider
        ),
        current: modules.find(
          (item) => item[this.column] === this.playSequenceSlider
        ),
        future: modules.filter(
          (item) => item[this.column] > this.playSequenceSlider
        ),
      };

      const moduleIdMappingLevels = this.wvbModelMapping.module_id.split('.');
      let objectsWithMapping = getObjectsWithMappingLevel(
        this.playerModelObjects,
        moduleIdMappingLevels
      );

      this.forgeViewer.hideAll();

      // display previous
      this.forgeViewer.show(
        this.$parent.$parent.$refs['forge-viewer'].mapExternalIdsToObjectIds(
          objectsWithMapping
            .filter(
              (object) =>
                renderData.previous.findIndex(
                  (module) =>
                    moduleIdMappingLevels.reduce(
                      (o, i) => o[i],
                      object.properties
                    ) === module.module_id
                ) !== -1
            )
            .map((object) => object.externalId)
        )
      );

      // select current build
      if (renderData.current) {
        let objects = objectsWithMapping.filter(
          (object) =>
            moduleIdMappingLevels.reduce((o, i) => o[i], object.properties) ===
            renderData.current.module_id
        );

        let objectIds = this.$parent.$parent.$refs[
          'forge-viewer'
        ].mapExternalIdsToObjectIds(objects.map((object) => object.externalId));

        this.forgeViewer.show(objectIds);
        this.forgeViewer.select(objectIds);
      }

      // hide future
      this.forgeViewer.hide(
        this.$parent.$parent.$refs['forge-viewer'].mapExternalIdsToObjectIds(
          objectsWithMapping
            .filter(
              (object) =>
                renderData.future.findIndex(
                  (module) =>
                    moduleIdMappingLevels.reduce(
                      (o, i) => o[i],
                      object.properties
                    ) === module.module_id
                ) !== -1
            )
            .map((object) => object.externalId)
        )
      );
    },
  },
};
</script>

<style scoped lang="scss">
.wvb-player {
  display: flex;
  flex-direction: column;
  padding: 10px 12px;
  margin: auto;
  max-width: 400px;

  .player-selector {
    display: flex;
    margin-left: 10px;
    align-items: center;
  }

  .player-slider {
    display: flex;
    width: 100%;
    align-items: center;
  }
}
</style>
