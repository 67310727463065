<template>
  <v-dialog
    v-model="dialog"
    width="unset"
    max-width="90%"
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card>
      <v-card-title>{{
        $t('modules.wvb.insertModule', { moduleId: module.module_id })
      }}</v-card-title>
      <v-card-text>
        <v-text-field
          v-model="newModulePosition"
          label="Position"
          type="number"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="blue darken-1" text @click="closeDialog">
          {{ $t('general.cancel') }}
        </v-btn>
        <v-btn color="red" text @click="insertModule">
          {{ $t('general.insert') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'WVBInsertDialog',
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    module: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      newModulePosition: undefined,
    };
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog');
    },
    insertModule() {
      this.$emit('insertModule', parseInt(this.newModulePosition));
    },
  },
};
</script>

<style scoped></style>
