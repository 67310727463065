<template>
  <div>
    <ant-input label="Phase" style="margin-top: 0 !important">
      <template #input-field>
        <v-select
          key="id"
          v-model="selectedPhase"
          placeholder="Phase"
          :items="sortedPhases"
          item-text="name"
          clearable
          return-object
          filled
          single-line
          dense
          hide-details
          @click:clear="resetPhaseSelect"
        />
      </template>
    </ant-input>
    <v-divider class="my-5" />
    <div class="ant-glass-background">
      <dynamic-data-table
        table-title="Modules"
        :table-headers="moduleHeaders"
        :table-records="tableRecords"
        :items-per-page="10"
        :clickable-rows="true"
        :has-options="true"
        :export-file-name="`wvb_modules`"
        :selected-row="selectedModule"
        @clickedRow="displayModule"
      >
        <template #item.module_id="{ value, rowId, item }">
          <td :class="{ selected: isModuleSelected(rowId) }">
            {{ value }}
          </td>
        </template>

        <template
          v-for="header in moduleHeaders.filter((tmp) => tmp.editable)"
          #[`item.${header.value}`]="{ value, rowId, item }"
        >
          <div :key="header.value" class="editable-field">
            <v-edit-dialog
              :return-value.sync="item[header.value]"
              large
              @save="save(item.id, header.value, item[header.value])"
            >
              <div class="editable-field">
                {{ item[header.value] }}
                <v-icon class="edit-icon ml-1" small> mdi-pencil</v-icon>
              </div>
              <template #input>
                <v-text-field
                  v-model="item[header.value]"
                  label="Edit"
                  single-line
                />
              </template>
            </v-edit-dialog>
          </div>
        </template>
        <template #item.placement_sequence="{ value, rowId, item }">
          <td>
            <div class="d-flex">
              <v-edit-dialog
                :return-value.sync="item.placement_sequence"
                large
                @save="
                  updateOrder(rowId, editedSequenceItem.placement_sequence)
                "
                @open="editedSequenceItem = Object.assign({}, item)"
                @close="editedSequenceItem = Object.assign({}, {})"
              >
                {{ value ? `${selectedPhase.number}.` : undefined }}{{ value }}
                <template #input>
                  <v-text-field
                    v-model="editedSequenceItem.placement_sequence"
                    label="Edit"
                    single-line
                    type="number"
                  />
                </template>
              </v-edit-dialog>
              <v-tooltip v-if="item.placement_sequence" left>
                <template #activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    class="mx-1 ant-icon"
                    small
                    v-on="on"
                    @click.stop="switchOrderUp(item, 'placement_sequence')"
                  >
                    mdi-arrow-up
                  </v-icon>
                </template>
                <span>{{ $t('modules.wvb.switchUp') }}</span>
              </v-tooltip>
              <v-tooltip v-if="item.placement_sequence" right>
                <template #activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    class="mx-1 ant-icon"
                    small
                    v-on="on"
                    @click.stop="switchOrderDown(item, 'placement_sequence')"
                  >
                    mdi-arrow-down
                  </v-icon>
                </template>
                <span>{{ $t('modules.wvb.switchDown') }}</span>
              </v-tooltip>
            </div>
          </td>
        </template>
        <template #item.phase="{ value, rowId, item }">
          <div>{{ selectedPhase.name }}</div>
        </template>
        <template #table-actions>
          <v-tooltip v-if="sortedModules.length > 0" bottom>
            <template #activator="{ on, attrs }">
              <v-icon
                class="ant-icon ml-2"
                @click="undoHighestAction"
                v-on="on"
              >
                mdi-undo</v-icon
              >
            </template>
            <span>
              {{
                $t('modules.wvb.undoPS', {
                  moduleId: sortedModules[sortedModules.length - 1].module_id,
                })
              }}</span
            >
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-icon
                class="ant-icon ml-2"
                :color="moduleSwitchToggle ? 'primary' : ''"
                @click="toggleModuleSwitch"
                v-on="on"
              >
                mdi-repeat</v-icon
              >
            </template>
            <span>{{ $t('modules.wvb.switchModuleWithinModel') }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-icon
                class="ant-icon ml-2"
                :color="playerToggle ? 'primary' : ''"
                :disabled="!canDisplayPlayer"
                @click="togglePlayer"
                v-on="on"
              >
                mdi-motion-play-outline</v-icon
              >
            </template>
            <span>{{ $t('modules.wvb.playerToggle') }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-icon
                class="ant-icon ml-2"
                :color="colorPhasesToggle ? 'primary' : ''"
                @click="colorPhases()"
                v-on="on"
              >
                mdi-format-color-highlight</v-icon
              >
            </template>
            <span>{{ $t('modules.wvb.colorPhases') }}</span>
          </v-tooltip>
          <v-tooltip v-if="tableRecords.length > 0" bottom>
            <template #activator="{ on, attrs }">
              <v-icon
                class="ant-icon ml-2"
                :color="colorOrderToggle ? 'primary' : ''"
                v-on="on"
                @click="renderPlacementSequence"
              >
                mdi-palette</v-icon
              >
            </template>
            <span>{{
              $t('modules.wvb.colorGradient', { mode: configureColumn })
            }}</span>
          </v-tooltip>
          <v-tooltip v-if="tableRecords.length > 0" bottom>
            <template #activator="{ on, attrs }">
              <v-icon
                class="ant-icon ml-2"
                :color="placementSequenceConfigureToggle ? 'primary' : ''"
                v-on="on"
                @click="toggleConfigure"
                >mdi-cog-transfer
              </v-icon>
            </template>
            <span>{{
              $t('modules.wvb.configure', { mode: configureColumn })
            }}</span>
          </v-tooltip>
        </template>

        <template #table-options-menu>
          <v-list-item @click="rebaseSequence()">
            <v-list-item-icon class="mr-2">
              <v-icon dense> mdi-format-list-numbered</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ $t('modules.wvb.rebasePlacementSequence') }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="resetPlacementSequenceSetup">
            <v-list-item-icon class="mr-2">
              <v-icon dense> mdi-cog-transfer</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ $t('modules.wvb.clearPlacementSequence') }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if="selectedPhase" @click="resetPhaseSetup">
            <v-list-item-icon class="mr-2">
              <v-icon dense> mdi-palette</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ $t('modules.wvb.clearPhase', { phase: selectedPhase.name }) }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="exportToExcel">
            <v-list-item-icon style="margin-right: 10px">
              <v-icon dense> mdi-export</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ $t('modules.wvb.exportExcel') }}
            </v-list-item-title>
          </v-list-item>
        </template>

        <template #table-row-actions="{ item }">
          <td style="display: flex">
            <v-tooltip left>
              <template #activator="{ on, attrs }">
                <v-icon
                  small
                  v-bind="attrs"
                  class="ant-icon mx-1"
                  v-on="on"
                  @click.stop="
                    wvbInsert.dialog = true;
                    wvbInsert.module = item;
                  "
                >
                  mdi-arrow-collapse-right
                </v-icon>
              </template>
              <span>{{ $t('modules.wvb.insert') }}</span>
            </v-tooltip>
            <v-tooltip left>
              <template #activator="{ on, attrs }">
                <v-icon
                  small
                  v-bind="attrs"
                  class="ant-icon mx-1"
                  v-on="on"
                  @click.stop="
                    wvbSwitch.dialog = true;
                    wvbSwitch.module = item;
                  "
                >
                  mdi-repeat
                </v-icon>
              </template>
              <span>{{ $t('modules.wvb.switchModule') }}</span>
            </v-tooltip>
          </td>
        </template>
      </dynamic-data-table>
    </div>

    <w-v-b-insert-dialog
      v-if="wvbInsert.module"
      :dialog="wvbInsert.dialog"
      :module="wvbInsert.module"
      @closeDialog="wvbInsert = { dialog: false, module: undefined }"
      @insertModule="insertModuleOnPosition"
    />
    <w-v-b-switch-dialog
      v-if="wvbSwitch.module"
      :dialog="wvbSwitch.dialog"
      :module="wvbSwitch.module"
      @closeDialog="wvbSwitch = { dialog: false, module: undefined }"
      @switchModule="switchModuleWithPosition"
    />

    <delete-dialog
      :dialog="phaseItem.hasOwnProperty('id')"
      :title="`Are you sure you want to reset ${phaseItem.name}?`"
      @closeDialog="closeDialogs"
      @deleteAction="resetPhase"
    />

    <delete-dialog
      :dialog="placementPhase.hasOwnProperty('id')"
      :title="`Are you sure you want to reset the placement sequence for ${placementPhase.name}?`"
      @closeDialog="closeDialogs"
      @deleteAction="resetPlacementSequence"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DynamicDataTable from '@/components/DynamicDataTable';
import DeleteDialog from '@/components/DeleteDialog';
import WVBInsertDialog from '@/components/Modules/Daiwa-House-Modular-Europe/WVB/DHME.WVB.InsertDialog';
import WVBSwitchDialog from '@/components/Modules/Daiwa-House-Modular-Europe/WVB/DHME.WVB.SwitchDialog';
import { executeAutomation } from '@/services/api/automation.api';
import moment from 'moment';
import {
  getObjectsWithMappingLevel,
  hexToVector4,
  pSBC,
} from '@/components/Modules/Daiwa-House-Modular-Europe/utils/DHME+utils';
import AntInput from '@/components/AntInput.vue';

export default {
  name: 'WVBPlacementSidebar',
  components: {
    AntInput,
    WVBSwitchDialog,
    WVBInsertDialog,
    DeleteDialog,
    DynamicDataTable,
  },
  data: () => {
    return {
      selectedPhase: undefined,
      moduleHeaders: [
        {
          text: 'module',
          value: 'module_id',
          hasSlot: true,
        },
        {
          text: 'type',
          value: 'module_type',
          editable: true,
          hasSlot: true,
        },
        {
          text: 'build number',
          value: 'build_nr',
          editable: true,
          hasSlot: true,
        },
        {
          text: 'house number',
          value: 'house_nr',
          editable: true,
          hasSlot: true,
        },
        {
          text: 'placement sequence',
          value: 'placement_sequence',
          configurable: true,
          type: 'order',
          hasSlot: true,
        },
        {
          text: 'assembly sequence',
          value: 'assembly_sequence',
          configurable: true,
          type: 'order',
        },
        {
          text: 'phase',
          value: 'phase',
          hasSlot: true,
        },
        { text: 'Actions', value: 'actions', align: 'right', sortable: false },
      ],
      phaseItem: {},
      placementPhase: {},
      colorPhasesToggle: false,
      colorOrderToggle: false,
      playerToggle: false,
      configureColumn: 'placement_sequence',
      editedSequenceItem: {},
      optionsMenu: false,
      placementSequenceConfigureToggle: false,
      selectedModule: [],
      moduleSwitchToggle: false,
      wvbInsert: {
        dialog: false,
        module: undefined,
      },
      wvbSwitch: {
        dialog: false,
        module: undefined,
      },
    };
  },
  computed: {
    ...mapGetters([
      'project',
      'wvbData',
      'wvbOrderColumn',
      'wvbPhases',
      'wvbModules',
      'wvbNonModules',
      'wvbSelectedPhase',
      'wvbHighlightedModule',
      'wvbModelMapping',
    ]),

    sortedPhases() {
      return [...this.wvbPhases].sort((a, b) => a.name.localeCompare(b.name));
    },
    tableRecords() {
      if (this.selectedPhase) {
        return this.wvbModules.filter(
          (record) => record.phase === this.selectedPhase.id
        );
      } else {
        return [];
      }
    },
    canDisplayPlayer() {
      return (
        this.tableRecords.filter((item) => item.placement_sequence).length > 0
      );
    },
    sortedModules() {
      return [...this.tableRecords]
        .filter((record) => record.placement_sequence)
        .sort(function (a, b) {
          return a.placement_sequence - b.placement_sequence;
        });
    },
    forgeViewer() {
      return this.$parent.$parent.$refs['wvb-viewer'].$refs['forge-viewer']
        .viewerService.Viewer3D;
    },
    forgeViewerService() {
      return this.$parent.$parent.$refs['wvb-viewer'].$refs['forge-viewer']
        .viewerService;
    },
    placementModelObjects() {
      return this.$parent.$parent.$refs['wvb-viewer'].modelObjects;
    },
  },
  watch: {
    selectedPhase(value) {
      if (value) {
        this.$store.dispatch('setPhase', this.selectedPhase);

        let placementSequence = this.wvbModules
          .filter((record) => record.phase === this.selectedPhase.id)
          .filter((record) => record.placement_sequence)
          .map((record) => record.placement_sequence)
          .sort(function (a, b) {
            return a - b;
          });

        if (placementSequence.length > 0) {
          this.$store.commit(
            'set_highest_sequence',
            placementSequence[placementSequence.length - 1]
          );
        } else {
          this.$store.commit('set_highest_sequence', 0);
        }

        this.resetView();
      }
    },
    wvbHighlightedModule(value) {
      this.$parent.$parent.$refs['wvb-viewer'].selectedModule =
        this.wvbModules.find((item) => item.module_id === value);
    },
  },
  mounted() {
    if (this.sortedPhases.length > 0) {
      this.selectedPhase = this.sortedPhases[0];
      this.$store.dispatch('setOrderColumn', this.configureColumn);
    }
  },
  methods: {
    exportToExcel() {
      let data = {
        modules: [],
        projectData: {
          name: this.project.name,
          number: this.project.number,
        },
      };
      data.modules = [...this.tableRecords].sort(
        (a, b) => a.placement_sequence - b.placement_sequence
      );

      executeAutomation('9ef53931-fc5c-11ec-9411-00ff47ce35fe', data).then(
        (response) => {
          const element = document.createElement('a');
          element.setAttribute('href', `data:xlsx;base64,${response.file}`);
          element.setAttribute(
            'download',
            `bouwvolgorde_${moment().format('YYYY-MM-DD')}.xlsx`
          );

          element.style.display = 'none';
          document.body.appendChild(element);

          element.click();

          document.body.removeChild(element);
        }
      );
    },
    rebaseSequence() {
      let records = [...this.sortedModules].filter(
        (record) => record.placement_sequence !== null
      );

      records.map((record, index) => {
        record.placement_sequence = index + 1;
        return record;
      });

      let data = records.map(({ id, module_id, assembly_sequence }) => ({
        id,
        module_id,
        assembly_sequence,
      }));

      this.$store.dispatch('importWvbModules', data).then(() => {
        this.$store.commit('showNotification', {
          color: 'success',
          content: this.$t('modules.wvb.notifications.rebasePlacementSequence'),
        });
      });
    },
    switchModuleWithPosition(position) {
      let records = [...this.tableRecords];

      let switchModule = records.find(
        (module) => module.placement_sequence === position
      );

      if (switchModule !== undefined) {
        let module = Object.assign({}, this.wvbSwitch.module);
        switchModule.placement_sequence = module.placement_sequence;
        module.placement_sequence = position;
        let modules = [];

        modules.push(module);
        modules.push(switchModule);

        modules.forEach((module) => delete module.session);

        this.$store.dispatch('importWvbModules', modules).then(() => {
          this.wvbSwitch = {
            dialog: false,
            module: undefined,
          };
          this.$store.commit('showNotification', {
            content: `Module: ${module.module_id} switched to position ${position}
            }`,
            color: 'success',
          });
        });
      } else {
        let module = Object.assign({}, this.wvbSwitch.module);
        this.$store
          .dispatch('updateWVBSequence', {
            recordId: module.id,
            column: 'placement_sequence',
            updatedSequence: position,
          })
          .then(() => {
            this.wvbSwitch = {
              dialog: false,
              module: undefined,
            };
            this.$store.commit('showNotification', {
              content: this.$t(
                'modules.wvb.notifications.moduleSwitchedPosition',
                {
                  module_id: module.module_id,
                  position: position,
                }
              ),
              color: 'success',
            });
          });
      }
    },
    insertModuleOnPosition(position) {
      let records = this.tableRecords.map((x) => {
        return { ...x };
      });

      if (
        records.find((module) => module.placement_sequence === position) !==
        undefined
      ) {
        let sorted = records.sort((a, b) => {
          return a.placement_sequence - b.placement_sequence;
        });

        let modulesAfterNewPosition;

        if (this.wvbInsert.module.placement_sequence > position) {
          modulesAfterNewPosition = sorted.filter(
            (module) => module.placement_sequence >= position
          );
        } else {
          modulesAfterNewPosition = sorted.filter(
            (module) =>
              module.placement_sequence >= position ||
              module.placement_sequence < this.wvbInsert.placement_sequence
          );
        }

        modulesAfterNewPosition.forEach((module) => {
          module.placement_sequence += 1;
        });

        let module = Object.assign({}, this.wvbInsert.module);
        module.placement_sequence = position;

        modulesAfterNewPosition.push(module);
        this.wvbInsert.module.placement_sequence = position;

        modulesAfterNewPosition.forEach((module) => delete module.session);

        this.$store
          .dispatch('importWvbModules', modulesAfterNewPosition)
          .then(() => {
            this.wvbInsert = {
              dialog: false,
              module: undefined,
            };
            this.$store.commit('showNotification', {
              content: this.$t(
                'modules.wvb.notifications.moduleInsertedPosition',
                {
                  module_id: module.module_id,
                  position: position,
                }
              ),
              color: 'success',
            });
          });
      } else {
        let module = Object.assign({}, this.wvbInsert.module);
        this.$store
          .dispatch('updateWVBSequence', {
            recordId: module.id,
            column: 'placement_sequence',
            updatedSequence: position,
          })
          .then(() => {
            this.wvbInsert = {
              dialog: false,
              module: undefined,
            };
            this.$store.commit('showNotification', {
              content: this.$t(
                'modules.wvb.notifications.moduleInsertedPosition',
                {
                  module_id: module.module_id,
                  position: position,
                }
              ),
              color: 'success',
            });
          });
      }
    },
    toggleModuleSwitch() {
      this.moduleSwitchToggle = !this.moduleSwitchToggle;

      if (this.moduleSwitchToggle) {
        // setup modulesSwitch
        this.placementSequenceConfigureToggle = false;
        this.$parent.$parent.$refs[
          'wvb-viewer'
        ].unloadEventAndEnableDefaultSelectionEvent();
        this.$parent.$parent.$refs['wvb-viewer'].configurationMode = true;
        this.$parent.$parent.$refs['wvb-viewer'].configurationMessage =
          `Switch modules`;
        this.$parent.$parent.$refs[
          'wvb-viewer'
        ].loadSelectionEventSwitchModules();
        document.addEventListener('keydown', this.closeSwitchMode);
      } else {
        // remove modulesSwitch event
        this.$parent.$parent.$refs[
          'wvb-viewer'
        ].unloadEventAndEnableDefaultSelectionEvent();
        this.$parent.$parent.$refs['wvb-viewer'].configurationMode = false;
        document.removeEventListener('keydown', this.closeSwitchMode);
      }
      this.$nextTick(() => {
        this.forgeViewerService.resizeView();
      });
    },
    closeSwitchMode(event) {
      if (event.key === 'Escape') {
        //do something
        this.moduleSwitchToggle = false;
        this.$parent.$parent.$refs[
          'wvb-viewer'
        ].unloadEventAndEnableDefaultSelectionEvent();
        this.$parent.$parent.$refs['wvb-viewer'].configurationMode = false;
        document.removeEventListener('keydown', this.closeSwitchMode);
      }
    },
    isModuleSelected(id) {
      return (
        this.$parent.$parent.$refs['wvb-viewer']?.selectedModule?.id === id
      );
    },
    undoHighestAction() {
      let module = this.sortedModules[this.sortedModules.length - 1];

      this.$store
        .dispatch('updateWVBSequence', {
          recordId: module.id,
          column: 'placement_sequence',
          updatedSequence: null,
        })
        .then(() => {
          if (this.sortedModules.length > 0) {
            this.$store.commit(
              'set_highest_sequence',
              this.sortedModules[this.sortedModules.length - 1]
            );
          } else {
            this.$store.commit('set_highest_sequence', 0);
          }
        });
    },
    resetView() {
      this.selectedModule = undefined;
      this.$nextTick(() => {
        const moduleIdMappingLevels = this.wvbModelMapping.module_id.split('.');
        let modules = this.tableRecords;

        const objectWithMappingLevel = getObjectsWithMappingLevel(
          this.placementModelObjects,
          moduleIdMappingLevels
        );
        let objectsInPhase = objectWithMappingLevel.filter((object) =>
          modules.some(
            (module) =>
              moduleIdMappingLevels.reduce(
                (o, i) => o[i],
                object.properties
              ) === module.module_id
          )
        );

        if (objectsInPhase.length > 0) {
          let objectIds = this.$parent.$parent.$refs['wvb-viewer'].$refs[
            'forge-viewer'
          ].mapExternalIdsToObjectIds(
            objectsInPhase.map((object) => object.externalId)
          );
          this.forgeViewer.isolate(objectIds);
          this.forgeViewer.fitToView(objectIds);
        } else {
          this.forgeViewer.hideAll();
        }
      });
    },
    togglePlayer() {
      this.playerToggle = !this.playerToggle;
      this.$parent.$parent.$refs['wvb-viewer'].playerDisplayed =
        this.playerToggle;
      this.$parent.$parent.$refs[
        'wvb-viewer'
      ].unloadEventAndEnableDefaultSelectionEvent();
      this.resetView();

      if (this.placementSequenceConfigureToggle) {
        this.toggleConfigure();
      }
      if (this.moduleSwitchToggle) {
        this.toggleModuleSwitch();
      }
      if (this.playerToggle) {
        this.$nextTick(() => {
          let sorted = [...this.tableRecords]
            .filter((item) => item.placement_sequence)
            .map((item) => item.placement_sequence)
            .sort(function (a, b) {
              return a - b;
            });
          this.$parent.$parent.$refs['wvb-viewer'].$refs[
            'wvb-player'
          ].maxPlaySequence = sorted[sorted.length - 1];
          this.$parent.$parent.$refs['wvb-viewer'].$refs['wvb-player'].modules =
            [...this.tableRecords];
          this.$parent.$parent.$refs['wvb-viewer'].$refs['wvb-player'].column =
            'placement_sequence';
        });
      } else {
        this.forgeViewer.clearSelection();
      }
    },
    toggleConfigure() {
      this.placementSequenceConfigureToggle =
        !this.placementSequenceConfigureToggle;

      if (this.playerToggle) {
        this.togglePlayer();
      }
      if (this.moduleSwitchToggle) {
        this.toggleModuleSwitch();
      }

      if (this.placementSequenceConfigureToggle) {
        this.$parent.$parent.$refs['wvb-viewer'].configurationMode = true;
        this.$parent.$parent.$refs['wvb-viewer'].configurationMessage =
          `Configuring placement sequence`;
        this.forgeViewer.clearSelection();
        this.$store.dispatch('setOrderColumn', this.configureColumn);
        this.$parent.$parent.$refs['wvb-viewer'].loadSelectionEventOrder();
        this.renderPlacementSequence();
        document.addEventListener('keydown', this.closeConfigurePlacementMode);
      } else {
        this.$parent.$parent.$refs['wvb-viewer'].configurationMode = false;
        this.$store.dispatch('resetOrderColumn');
        this.$parent.$parent.$refs[
          'wvb-viewer'
        ].unloadEventAndEnableDefaultSelectionEvent();
        document.removeEventListener(
          'keydown',
          this.closeConfigurePlacementMode
        );
      }
      this.$nextTick(() => {
        this.forgeViewerService.resizeView();
      });
    },
    closeConfigurePlacementMode(event) {
      if (event.key === 'Escape') {
        //do something
        this.placementSequenceConfigureToggle = false;
        this.$parent.$parent.$refs['wvb-viewer'].configurationMode = false;
        this.$store.dispatch('resetOrderColumn');
        this.$parent.$parent.$refs[
          'wvb-viewer'
        ].unloadEventAndEnableDefaultSelectionEvent();
        document.removeEventListener(
          'keydown',
          this.closeConfigurePlacementMode
        );
      }
    },
    resetPhaseSelect() {
      this.forgeViewerService.displayAllWithTheming();
      this.$store.dispatch('resetPhase');
    },
    closeDialogs() {
      this.phaseItem = Object.assign({}, {});
      this.placementPhase = Object.assign({}, {});
    },
    resetPhaseSetup() {
      this.phaseItem = this.selectedPhase;
    },
    resetPhase() {
      let records = [...this.tableRecords];

      records.map((record) => {
        record.phase = null;
        record.phase_number = null;
        record.placement_sequence = null;
        return record;
      });

      let data = records.map(
        ({ id, module_id, phase, phase_number, placement_sequence }) => ({
          id,
          module_id,
          phase,
          phase_number,
          placement_sequence,
        })
      );

      this.$store.dispatch('importWvbModules', data).then(() => {
        let records = [...this.wvbNonModules].filter(
          (record) => record.phase === this.phaseItem.name
        );

        records.map((record) => {
          record.phase = null;
          record.phase_number = null;
          return record;
        });

        let data = records.map(
          ({ id, non_module_id, phase, phase_number }) => ({
            id,
            non_module_id,
            phase,
            phase_number,
          })
        );

        this.$store.dispatch('importWvbNonModules', data).then(() => {
          this.$store.commit('showNotification', {
            color: 'success',
            content: `Successfully reset ${this.phaseItem.name}`,
          });
          this.closeDialogs();
          this.forgeViewerService.clearSearch();
        });
      });
    },
    resetPlacementSequenceSetup() {
      this.placementPhase = this.selectedPhase;
    },
    resetPlacementSequence() {
      let records = [...this.tableRecords].filter(
        (record) => record.placement_sequence !== null
      );

      records.map((record) => {
        record.placement_sequence = null;
        return record;
      });

      let data = records.map(({ id, module_id, placement_sequence }) => ({
        id,
        module_id,
        placement_sequence,
      }));

      this.$store.dispatch('importWvbModules', data).then(() => {
        this.$store.commit('showNotification', {
          color: 'success',
          content: this.$t('modules.wvb.notifications.buildOrderReset'),
        });
        this.closeDialogs();
        this.$store.commit('set_highest_sequence', 0);
        this.forgeViewer.clearThemingColors();
      });
    },
    colorPhases() {
      this.colorPhasesToggle = !this.colorPhasesToggle;
      this.colorOrderToggle = false;
      this.$parent.$parent.$refs['wvb-viewer'].colorTypesToggle = false;

      if (this.colorPhasesToggle) {
        const moduleIdMappingLevels = this.wvbModelMapping.module_id.split('.');

        this.forgeViewer.clearThemingColors();

        this.sortedPhases.forEach((phase) => {
          let modulesInPhase = this.wvbModules.filter(
            (record) => record.phase === phase.id
          );
          let nonModulesInPhase = this.wvbNonModules.filter(
            (record) => record.phase === phase.id
          );

          let moduleObjects = getObjectsWithMappingLevel(
            this.placementModelObjects,
            moduleIdMappingLevels
          ).filter(
            (object) =>
              modulesInPhase.findIndex(
                (module) =>
                  moduleIdMappingLevels.reduce(
                    (o, i) => o[i],
                    object.properties
                  ) === module.module_id
              ) !== -1
          );
          let nonModuleObjects = getObjectsWithMappingLevel(
            this.placementModelObjects,
            moduleIdMappingLevels
          ).filter(
            (object) =>
              nonModulesInPhase.findIndex(
                (module) =>
                  moduleIdMappingLevels.reduce(
                    (o, i) => o[i],
                    object.properties
                  ) === module.non_module_id
              ) !== -1
          );
          let objects = [...moduleObjects, ...nonModuleObjects];

          let color = hexToVector4(phase.color);
          let objectIds = this.$parent.$parent.$refs['wvb-viewer'].$refs[
            'forge-viewer'
          ].mapExternalIdsToObjectIds(
            objects.map((object) => object.externalId)
          );
          objectIds.forEach((id) => {
            this.forgeViewer.setThemingColor(id, color, null, true);
          });
        });
      } else {
        this.forgeViewer.clearThemingColors();
      }
    },
    save(recordId, column, value) {
      let body = {
        project: {
          id: this.project.id,
        },
        table: {
          id: this.wvbData.CFFA_DHME_MODULES.id,
        },
        record: {},
      };

      body.record[column] = value;

      this.$store.dispatch('updateWVBRecord', { recordId: recordId, body });
    },

    renderPlacementSequence() {
      this.colorOrderToggle = !this.colorOrderToggle;
      this.colorPhasesToggle = false;
      this.$parent.$parent.$refs['wvb-viewer'].colorTypesToggle = false;

      if (this.colorOrderToggle) {
        this.forgeViewer.clearThemingColors();

        const moduleIdMappingLevels = this.wvbModelMapping.module_id.split('.');
        let objectsWithMapping = getObjectsWithMappingLevel(
          this.placementModelObjects,
          moduleIdMappingLevels
        );

        let sortedModules = this.wvbModules
          .filter(
            (record) =>
              record.phase === this.selectedPhase.id &&
              record.placement_sequence
          )
          .sort(function (a, b) {
            return a.placement_sequence - b.placement_sequence;
          });

        sortedModules.reverse().forEach((module, index) => {
          let objects = objectsWithMapping.filter(
            (object) =>
              moduleIdMappingLevels.reduce(
                (o, i) => o[i],
                object.properties
              ) === module.module_id
          );

          let percent =
            (Math.floor((index / sortedModules.length) * 200) - 95) / 100;
          let colorHex = pSBC(percent, this.selectedPhase.color);
          let color = hexToVector4(colorHex);

          let objectIds = this.$parent.$parent.$refs['wvb-viewer'].$refs[
            'forge-viewer'
          ].mapExternalIdsToObjectIds(
            objects.map((object) => object.externalId)
          );

          objectIds.forEach((id) => {
            this.forgeViewer.setThemingColor(id, color, null, true);
          });
        });
      } else {
        this.forgeViewer.clearThemingColors();
      }
    },
    switchOrderUp(item) {
      let switchItem = undefined;
      switchItem = this.wvbModules.find(
        (build) =>
          build.placement_sequence === parseInt(item.placement_sequence) + 1 &&
          build.phase === this.selectedPhase.id
      );

      if (switchItem !== undefined) {
        this.$store.dispatch('updateWVBSequence', {
          recordId: switchItem.id,
          column: 'placement_sequence',
          updatedSequence: parseInt(item.placement_sequence),
        });
      }
      this.$store.dispatch('updateWVBSequence', {
        recordId: item.id,
        column: 'placement_sequence',
        updatedSequence: parseInt(item.placement_sequence) + 1,
      });

      this.$store.commit('showNotification', {
        content: this.$t('modules.wvb.notifications.moduleSetOnPosition', {
          module_id: item.module_id,
          position: parseInt(item.placement_sequence) + 1,
        }),
        color: 'success',
      });
    },
    switchOrderDown(item) {
      if (parseInt(item.placement_sequence) - 1 > 0) {
        let switchItem = undefined;
        switchItem = this.wvbModules.find(
          (build) =>
            build.placement_sequence ===
              parseInt(item.placement_sequence) - 1 &&
            build.phase === this.wvbSelectedPhase.name
        );

        if (switchItem !== undefined) {
          this.$store
            .dispatch('updateWVBSequence', {
              recordId: switchItem.id,
              column: 'placement_sequence',
              updatedSequence: parseInt(item.placement_sequence),
            })
            .then((response) => {});
        }
        this.$store
          .dispatch('updateWVBSequence', {
            recordId: item.id,
            column: 'placement_sequence',
            updatedSequence: parseInt(item.placement_sequence) - 1,
          })
          .then((response) => {});

        this.$store.commit('showNotification', {
          content: this.$t('modules.wvb.notifications.moduleSetOnPosition', {
            module_id: item.module_id,
            position: parseInt(item.placement_sequence) - 1,
          }),
          color: 'success',
        });
      } else {
        this.$store.commit('showNotification', {
          content: this.$t('modules.wvb.cannotBeLower'),
          color: 'error',
        });
      }
    },
    updateOrder(id, sequence) {
      this.$store.dispatch('updateWVBSequence', {
        recordId: id,
        column: 'placement_sequence',
        updatedSequence: parseInt(sequence),
      });
    },
    displayModule(module) {
      if (this.$parent.$parent.$refs['wvb-viewer'].selectedModule !== module) {
        this.$parent.$parent.$refs['wvb-viewer'].selectedModule = module;
        const moduleIdMappingLevels = this.wvbModelMapping.module_id.split('.');

        const objects = getObjectsWithMappingLevel(
          this.placementModelObjects,
          moduleIdMappingLevels
        ).filter(
          (object) =>
            moduleIdMappingLevels.reduce((o, i) => o[i], object.properties) ===
            module.module_id
        );
        let objectIds = this.$parent.$parent.$refs['wvb-viewer'].$refs[
          'forge-viewer'
        ].mapExternalIdsToObjectIds(objects.map((object) => object.externalId));
        this.forgeViewer.select(objectIds);
      } else {
        this.$parent.$parent.$refs['wvb-viewer'].clearSearch();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.editable-field {
  display: flex;

  .edit-icon {
    opacity: 0;
    transition: 200ms;
  }

  &:hover {
    .edit-icon {
      opacity: 1;
    }
  }
}

.selected {
  border-left: solid 5px var(--v-primary-base);
}
</style>
