<template>
  <div class="wvb-viewer-container">
    <div v-if="configurationMode" class="configuration-bar">
      {{ configurationMessage }}
    </div>
    <ant-toolbar-container ant-toolbar-prefix="dhme-wvb" />
    <forge-viewer
      ref="forge-viewer"
      class="forge-viewer"
      :style="{
        border: configurationMode ? 'solid 5px var(--v-primary-base)' : 'none',
      }"
      :client="wvbViewerClient"
      :models="wvbViewerModels"
      :extensions="['Autodesk.DocumentBrowser', 'Autodesk.VisualClusters']"
      :extension-options="[]"
      ant-toolbar-prefix="dhme-wvb"
      :ant-toolbar-options="{
        viewType: {
          display: true,
          enabled: false,
        },
        performanceMode: {
          display: true,
          enabled: true,
        },
        models: {
          display: false,
          enabled: false,
        },
        antTable: {
          display: false,
          enabled: false,
        },
        ghosting: {
          display: true,
          enabled: false,
        },
        modelTree: {
          display: false,
          enabled: false,
        },
        clearIsolation: {
          display: true,
          enabled: false,
        },
        objectProperties: {
          display: false,
          enabled: true,
        },
        sidebar: {
          display: false,
          enabled: true,
        },
      }"
      @modelsRendered="setModelObjects"
    >
      <template #ant-forge-toolbar-actions>
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              tile
              icon
              v-bind="attrs"
              :ripple="false"
              :color="displayTypeLegend ? 'primary' : ''"
              v-on="on"
              @click="displayTypeLegend = !displayTypeLegend"
            >
              <v-icon> mdi-group</v-icon>
            </v-btn>
          </template>
          <span>{{ text('toggleModuleTypes') }}</span>
        </v-tooltip>
      </template>

      <template #forge-overlay-container>
        <transition name="fade">
          <v-chip
            v-if="selectedModule"
            color="primary"
            class="highlight-chip"
            close
            @click:close="resetHighlight"
          >
            {{ selectedModuleIndex.placement_sequence }} ({{
              selectedModuleIndex.module_id
            }})
          </v-chip>
        </transition>

        <transition name="fade">
          <div
            v-if="displayTypeLegend"
            class="forge-legend ant-glass-background"
          >
            <div class="legend-title">
              <div class="flex-grow-1">
                {{ text('moduleItems') }}
              </div>
              <v-tooltip left>
                <template #activator="{ on, attrs }">
                  <v-icon
                    :color="colorTypesToggle ? 'primary' : ''"
                    class="mx-1"
                    @click="toggleColorModuleTypes()"
                    v-on="on"
                  >
                    mdi-format-color-highlight
                  </v-icon>
                </template>
                <span>{{ text('colorModules') }}</span>
              </v-tooltip>
            </div>
            <ul class="legend-list flex">
              <li
                v-for="type in distinctModules"
                :key="type.type"
                class="legend-item"
                :class="{ active: type.type === isolatedType }"
                @click="isolateType(type.type)"
              >
                <div
                  class="legend-item-color"
                  :style="'background-color:' + type.color"
                />
                <div class="legend-item-title">
                  {{ type.type }}
                </div>
              </li>
            </ul>
          </div>
        </transition>

        <div v-if="modelLayers.length > 0" class="wvb-model-layers">
          <div v-for="layer in modelLayers" :key="layer">
            <v-tooltip left>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  class="btn-layer elevation-0"
                  width="40"
                  height="40"
                  tile
                  min-width="0"
                  :color="isolatedLayer === layer ? 'primary' : ''"
                  v-on="on"
                  @click="toggleLayer(layer)"
                >
                  {{ modelLayers.findIndex((x) => x === layer) }}
                </v-btn>
              </template>
              <span>{{ layer }}</span>
            </v-tooltip>
            <v-tooltip />
          </div>
        </div>

        <transition name="fade">
          <w-v-b-player
            v-if="playerDisplayed"
            ref="wvb-player"
            class="wvb-player-container"
          />
        </transition>
      </template>
    </forge-viewer>
  </div>
</template>

<script>
import ForgeViewer from '@/components/Modules/Forge/ForgeViewer';
import { mapGetters } from 'vuex';
import AntToolbarContainer from '@/components/AntToolbarContainer';
import WVBPlayer from '@/components/Modules/Daiwa-House-Modular-Europe/WVB/DHME.WVB.Player';
import {
  getObjectsWithMappingLevel,
  hexToVector4,
} from '@/components/Modules/Daiwa-House-Modular-Europe/utils/DHME+utils';

export default {
  name: 'WVBViewer',
  components: { AntToolbarContainer, WVBPlayer, ForgeViewer },
  data: () => {
    return {
      modelLayers: [],
      selectedModule: undefined,
      playerDisplayed: false,
      ghostToggle: false,
      isolatedLayer: undefined,
      isolatedType: undefined,
      colorTypesToggle: false,
      displayTypeLegend: false,
      configurationMode: false,
      configurationMessage: undefined,
      selectionBoundEvent: undefined,
      currentForgeSelection: undefined,
      modulesSwitch: [],
    };
  },
  computed: {
    ...mapGetters([
      'wvbViewerClient',
      'wvbViewerModels',
      'wvbPhases',
      'wvbModules',
      'wvbHighlightedModule',
      'wvbModelMapping',
      'wvbModelMappings',
      'wvbSelectedPhase',
      'wvbNonModules',
      'wvbOrderColumn',
    ]),

    distinctModules() {
      let array = [...this.wvbModules];
      let result = [];
      let items = [...new Set(array.map((item) => item.module_type))].sort();
      items.forEach((type) => {
        result.push({ type: type, color: this.getRandomColor() });
      });
      return result;
    },
    modelObjects() {
      return this.$refs['forge-viewer'].modelObjects.flatMap(
        (item) => item.properties
      );
    },
    forgeViewer() {
      return this.$refs['forge-viewer'].viewerService.Viewer3D;
    },
    forgeViewerService() {
      return this.$refs['forge-viewer'].viewerService;
    },
  },
  watch: {
    configurationMode(value) {
      this.$refs['forge-viewer'].viewerService.resizeView();
    },
  },
  methods: {
    setModelObjects(value) {
      this.selectionBoundEvent = this.onDefaultViewerSelectionEvent.bind(this);
      // eslint-disable-next-line no-undef
      this.forgeViewer.addEventListener(
        Autodesk.Viewing.SELECTION_CHANGED_EVENT,
        this.selectionBoundEvent
      );

      this.calculateLayers();
      this.calculateModules();

      this.$refs['forge-viewer'].setExternalMapping(value.myData.urn);

      this.$emit('viewerLoaded');
    },
    resetHighlight() {
      this.$store.commit('reset_highlighted_module_id');
      this.$refs['forge-viewer'].viewerService.Viewer3D.clearSelection();
    },
    clearSearch() {
      this.selectedModule = undefined;
      this.$emit('clearModuleSelection');
      this.$store.commit('reset_highlighted_module_id');
      this.$refs['forge-viewer'].viewerService.Viewer3D.clearSelection();
    },
    calculateLayers() {
      let tmp = [...this.modelObjects];
      let x = tmp.filter((x) => x.properties.Item.Layer !== undefined);
      this.modelLayers = [...new Set(x.map((y) => y.properties.Item.Layer))];
    },
    calculateModules() {
      const mappingLevels = this.wvbModelMapping.module_id.split('.');
      let moduleObjects = getObjectsWithMappingLevel(
        this.modelObjects,
        mappingLevels
      ).map((object) => ({
        module_id: mappingLevels.reduce((o, i) => o[i], object.properties),
      }));

      let distinct = [
        ...new Map(
          moduleObjects
            .filter((item) => item.module_id)
            .map((item) => [item.module_id, item])
        ).values(),
      ];

      this.$emit('modulesInModel', distinct);
    },
    toggleLayer(layer) {
      if (this.isolatedLayer === layer) {
        this.isolatedLayer = undefined;
        this.forgeViewerService.displayAllWithTheming();
      } else {
        this.isolatedLayer = layer;

        this.wvbViewerModels
          .filter((m) => m.enabled)
          .forEach((model) => {
            let viewerModel = this.forgeViewer.impl
              .modelQueue()
              .getModels()
              .find((x) => model.urn.includes(x.myData.urn));

            let modelObjects = this.$refs['forge-viewer'].modelObjects.find(
              (item) => item.urn === model.urn
            ).properties;

            let objects = modelObjects.filter(
              (object) =>
                String(object.properties.Item.Layer) !== String(layer) &&
                object.properties.Item.Layer !== undefined
            );
            let objectIds = this.$refs[
              'forge-viewer'
            ].mapExternalIdsToObjectIdsMultiModel(
              model.urn,
              objects.map((object) => object.externalId)
            );
            this.forgeViewer.showAll(viewerModel);
            this.forgeViewer.hide(objectIds, viewerModel);
            this.forgeViewer.fitToView(objectIds, viewerModel);
          });
      }
    },
    isolateType(type) {
      if (type === this.isolatedType) {
        this.isolatedType = undefined;
        this.forgeViewerService.displayAllWithTheming();
      } else {
        this.isolatedType = type;
        this.$refs['forge-viewer'].searchInModel(type);
      }
    },
    toggleColorModuleTypes() {
      this.colorTypesToggle = !this.colorTypesToggle;
      this.$emit('toggleColorsOff');

      if (this.colorTypesToggle) {
        this.forgeViewer.clearThemingColors();

        this.wvbViewerModels
          .filter((m) => m.enabled)
          .forEach((model) => {
            const mapping = this.wvbModelMappings.find(
              (record) => record.model === model.id
            );

            let viewerModel = this.forgeViewer.impl
              .modelQueue()
              .getModels()
              .find((x) => model.urn.includes(x.myData.urn));

            let modelObjects = this.$refs['forge-viewer'].modelObjects.find(
              (item) => item.urn === model.urn
            ).properties;

            const moduleTypeMappingLevels = mapping.module_type.split('.');
            let moduleObjects = getObjectsWithMappingLevel(
              modelObjects,
              moduleTypeMappingLevels
            );

            this.distinctModules.forEach((module) => {
              let objects = moduleObjects.filter(
                (object) =>
                  moduleTypeMappingLevels.reduce(
                    (o, i) => o[i],
                    object.properties
                  ) === module.type
              );

              let color = hexToVector4(module.color);
              let objectIds = this.$refs[
                'forge-viewer'
              ].mapExternalIdsToObjectIdsMultiModel(
                model.urn,
                objects.map((object) => object.externalId)
              );
              objectIds.forEach((id) => {
                this.forgeViewer.setThemingColor(id, color, viewerModel, true);
              });
            });
          });
      } else {
        this.forgeViewer.clearThemingColors();
      }
    },
    getRandomColor() {
      let letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    text() {
      const [path, ...params] = arguments;
      return this.$t(`modules.wvb.${path}`, params);
    },
    unloadEventAndEnableDefaultSelectionEvent() {
      this.forgeViewer.removeEventListener(
        Autodesk.Viewing.SELECTION_CHANGED_EVENT,
        this.selectionBoundEvent
      );
      this.selectionBoundEvent = this.onDefaultViewerSelectionEvent.bind(this);
      this.forgeViewer.addEventListener(
        Autodesk.Viewing.SELECTION_CHANGED_EVENT,
        this.selectionBoundEvent
      );
    },
    onDefaultViewerSelectionEvent() {
      let selection = this.forgeViewer.getSelection();

      if (selection !== this.currentForgeSelection && selection.length > 0) {
        this.currentForgeSelection = selection;

        let externalIds =
          this.$refs['forge-viewer'].getExternalIdsByNewObjectIds(selection);

        let objectsSelection = this.modelObjects.filter((object) => {
          return externalIds.some((id) => id === object.externalId);
        });

        const moduleIdMappingLevels = this.wvbModelMapping.module_id.split('.');
        let moduleObjects = getObjectsWithMappingLevel(
          objectsSelection,
          moduleIdMappingLevels
        );

        if (moduleObjects.length > 0) {
          const moduleIds = [
            ...new Set(
              moduleObjects.map((object) =>
                moduleIdMappingLevels.reduce((o, i) => o[i], object.properties)
              )
            ),
          ];

          if (moduleIds.length === 1) {
            this.$store.commit('set_highlighted_module_id', moduleIds[0]);
          } else {
            this.$store.commit('showNotification', {
              content: 'Can only select 1 module within this mode',
              color: 'error',
            });
          }
        } else {
          this.$store.commit('showNotification', {
            content: `${this.wvbModelMapping.module_id} not found in selected object(s)`,
            color: 'error',
          });
        }
      }
    },
    loadSelectionEventPhases() {
      this.forgeViewer.removeEventListener(
        Autodesk.Viewing.SELECTION_CHANGED_EVENT,
        this.selectionBoundEvent
      );
      this.selectionBoundEvent = this.onPhaseViewerSelectionEvent.bind(this);
      this.forgeViewer.addEventListener(
        Autodesk.Viewing.SELECTION_CHANGED_EVENT,
        this.selectionBoundEvent
      );
    },
    onPhaseViewerSelectionEvent() {
      let selection = this.forgeViewer.getSelection();

      if (selection !== this.currentForgeSelection && selection.length > 0) {
        this.currentForgeSelection = selection;

        let externalIds =
          this.$refs['forge-viewer'].getExternalIdsByNewObjectIds(selection);

        let objectsSelection = this.modelObjects.filter((object) => {
          return externalIds.some((id) => id === object.externalId);
        });

        const moduleIdMappingLevels = this.wvbModelMapping.module_id.split('.');
        let selectionObjectsWithMappingLevel = getObjectsWithMappingLevel(
          objectsSelection,
          moduleIdMappingLevels
        );
        let objectsWithMappingLevel = getObjectsWithMappingLevel(
          this.modelObjects,
          moduleIdMappingLevels
        );

        if (selectionObjectsWithMappingLevel.length > 0) {
          const moduleNrs = selectionObjectsWithMappingLevel
            .map((object) => ({
              id: null,
              module_id: moduleIdMappingLevels.reduce(
                (o, i) => o[i],
                object.properties
              ),
            }))
            .filter((x) => x.module_id);

          const uniqueModules = [
            ...new Map(
              moduleNrs.map((module) => [module.module_id, module])
            ).values(),
          ];

          if (uniqueModules.length > 0) {
            let allObjects = [];
            uniqueModules.forEach((x) => {
              if (x !== '') {
                let objects = objectsWithMappingLevel.filter(
                  (object) =>
                    moduleIdMappingLevels.reduce(
                      (o, i) => o[i],
                      object.properties
                    ) === x.module_id
                );
                allObjects = [...allObjects, ...objects];
              }
            });

            if (this.wvbSelectedPhase) {
              let PhaseColor = this.wvbSelectedPhase.color;

              this.wvbViewerModels
                .filter((m) => m.enabled)
                .forEach((model) => {
                  let viewerModel = this.forgeViewer.impl
                    .modelQueue()
                    .getModels()
                    .find((x) => model.urn.includes(x.myData.urn));

                  let objectIds = this.$refs[
                    'forge-viewer'
                  ].mapExternalIdsToObjectIdsMultiModel(
                    model.urn,
                    allObjects.map((object) => object.externalId)
                  );

                  objectIds.forEach((id) => {
                    this.forgeViewer.setThemingColor(
                      id,
                      hexToVector4(PhaseColor),
                      viewerModel,
                      true
                    );
                  });
                });

              if (uniqueModules.length > 1) {
                let modules = uniqueModules
                  .filter(
                    (module) => !module.module_id.startsWith('NonModuleObject;')
                  )
                  .map((module) => {
                    module.id = this.wvbModules.find(
                      (item) => item.module_id === module.module_id
                    ).id;
                    module.phase = this.wvbSelectedPhase.id;
                    module.placement_sequence = null;
                    module.assembly_sequence = null;
                    return module;
                  });

                let nonModules = uniqueModules
                  .filter((module) =>
                    module.module_id.startsWith('NonModuleObject;')
                  )
                  .map((module) => {
                    module.id = this.wvbNonModules.find(
                      (item) => item.non_module_id === module.module_id
                    ).id;
                    module.non_module_id = module.module_id;
                    module.phase = this.wvbSelectedPhase.id;
                    delete module.module_id;
                    return module;
                  });

                if (modules.length > 0) {
                  this.$store.dispatch('importWvbModules', modules);
                }

                if (nonModules.length > 0) {
                  this.$store.dispatch('importWvbNonModules', nonModules);
                }
              } else {
                let module = uniqueModules[0];

                this.$store.dispatch('addModuleToPhase', {
                  moduleId: module.module_id,
                });
              }
            }
          } else {
            this.$store.commit('showNotification', {
              content: 'Equipment number and found for selected object',
              color: 'error',
            });
          }
        } else {
          this.$store.commit('showNotification', {
            content: `${this.wvbModelMapping.module_id} not found in selected object(s)`,
            color: 'error',
          });
        }
      }
    },
    loadSelectionEventSwitchModules() {
      this.forgeViewer.removeEventListener(
        Autodesk.Viewing.SELECTION_CHANGED_EVENT,
        this.selectionBoundEvent
      );
      this.selectionBoundEvent = this.onSwitchViewerSelectionEvent.bind(this);
      this.forgeViewer.addEventListener(
        Autodesk.Viewing.SELECTION_CHANGED_EVENT,
        this.selectionBoundEvent
      );
    },
    onSwitchViewerSelectionEvent() {
      let selection = this.forgeViewer.getSelection();

      if (selection !== this.currentForgeSelection && selection.length > 0) {
        this.currentForgeSelection = selection;

        let externalIds =
          this.$refs['forge-viewer'].getExternalIdsByNewObjectIds(selection);

        let objectsSelection = this.modelObjects.filter((object) => {
          return externalIds.some((id) => id === object.externalId);
        });

        const moduleIdMappingLevels = this.wvbModelMapping.module_id.split('.');
        let selectionObjectsWithMappingLevel = getObjectsWithMappingLevel(
          objectsSelection,
          moduleIdMappingLevels
        );

        if (selectionObjectsWithMappingLevel.length > 0) {
          const moduleNrs = selectionObjectsWithMappingLevel
            .map((object) => ({
              id: null,
              module_id: moduleIdMappingLevels.reduce(
                (o, i) => o[i],
                object.properties
              ),
            }))
            .filter((x) => x.module_id);

          const uniqueModules = [
            ...new Map(
              moduleNrs.map((module) => [module.module_id, module])
            ).values(),
          ];

          if (uniqueModules.length === 1) {
            let module = Object.assign(
              {},
              this.wvbModules.find(
                (module) => module.module_id === uniqueModules[0].module_id
              )
            );

            if (module[this.wvbOrderColumn]) {
              this.modulesSwitch.push(module);

              if (this.modulesSwitch.length === 2) {
                // do modulesSwitch
                let positions = [...this.modulesSwitch].map(
                  (item) => item[this.wvbOrderColumn]
                );
                let modules = [...this.modulesSwitch];
                modules[0][this.wvbOrderColumn] = positions[1];
                modules[1][this.wvbOrderColumn] = positions[0];

                modules.forEach((module) => delete module.session);

                this.$store.dispatch('importWvbModules', modules).then(() => {
                  this.modulesSwitch = [];
                  this.$store.commit('showNotification', {
                    content: `successfully switched ${modules[0].module_id} with ${modules[1].module_id}`,
                    color: 'success',
                  });
                });
              }
            } else {
              this.$store.commit('showNotification', {
                content: `${this.wvbOrderColumn} is not set for module ${module.module_id}`,
                color: 'error',
              });
            }
          } else {
            this.$store.commit('showNotification', {
              content: 'Can only select 1 module within this mode',
              color: 'error',
            });
          }
        } else {
          this.$store.commit('showNotification', {
            content: `${this.wvbModelMapping.module_id} not found in selected object(s)`,
            color: 'error',
          });
        }
      }
    },
    loadSelectionEventOrder() {
      this.forgeViewer.removeEventListener(
        Autodesk.Viewing.SELECTION_CHANGED_EVENT,
        this.selectionBoundEvent
      );
      this.selectionBoundEvent = this.onOrderViewerSelectionEvent.bind(this);
      this.forgeViewer.addEventListener(
        Autodesk.Viewing.SELECTION_CHANGED_EVENT,
        this.selectionBoundEvent
      );
    },
    onOrderViewerSelectionEvent() {
      let selection = this.forgeViewer.getSelection();

      if (selection !== this.currentForgeSelection && selection.length > 0) {
        this.currentForgeSelection = selection;

        let externalIds =
          this.$refs['forge-viewer'].getExternalIdsByNewObjectIds(selection);

        let objectsSelection = this.modelObjects.filter((object) => {
          return externalIds.some((id) => id === object.externalId);
        });

        const moduleIdMappingLevels = this.wvbModelMapping.module_id.split('.');
        let selectionObjectsWithMappingLevel = getObjectsWithMappingLevel(
          objectsSelection,
          moduleIdMappingLevels
        );
        let objectsWithMappingLevel = getObjectsWithMappingLevel(
          this.modelObjects,
          moduleIdMappingLevels
        );

        if (selectionObjectsWithMappingLevel.length > 0) {
          const moduleNrs = selectionObjectsWithMappingLevel
            .map((object) => ({
              id: null,
              module_id: moduleIdMappingLevels.reduce(
                (o, i) => o[i],
                object.properties
              ),
            }))
            .filter((x) => x.module_id);

          const uniqueModules = [
            ...new Map(
              moduleNrs.map((module) => [module.module_id, module])
            ).values(),
          ];

          if (uniqueModules.length > 0) {
            let allObjects = [];
            uniqueModules.forEach((x) => {
              if (x !== '') {
                let objects = objectsWithMappingLevel.filter(
                  (object) =>
                    moduleIdMappingLevels.reduce(
                      (o, i) => o[i],
                      object.properties
                    ) === x.module_id
                );
                allObjects = [...allObjects, ...objects];
              }
            });

            let objectIds = this.$refs[
              'forge-viewer'
            ].mapExternalIdsToObjectIds(
              allObjects.map((object) => object.externalId)
            );

            if (this.wvbSelectedPhase) {
              let PhaseColor = this.wvbSelectedPhase.color;
              objectIds.forEach((object) => {
                this.forgeViewer.setThemingColor(
                  object.objectid,
                  hexToVector4(PhaseColor),
                  null,
                  true
                );
              });
            } else {
              let PhaseColor = this.$vuetify.theme.themes.light.primary;
              objectIds.forEach((object) => {
                this.forgeViewer.setThemingColor(
                  object.objectid,
                  hexToVector4(PhaseColor),
                  null,
                  true
                );
              });
            }

            if (this.wvbOrderColumn !== undefined) {
              uniqueModules.forEach((item) => {
                this.$store.dispatch('updateModuleSequence', {
                  moduleId: item.module_id,
                });
              });
            }
          } else {
            this.$store.commit('showNotification', {
              content: 'Equipment number not found for selected object',
              color: 'error',
            });
          }
        } else {
          this.$store.commit('showNotification', {
            content: `${this.wvbModelMapping.module_id} not found in selected object(s)`,
            color: 'error',
          });
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.wvb-viewer-container {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  .highlight-chip {
    position: absolute;
    top: 10px;
    left: calc(50% - 50px);
    z-index: 2;
  }

  .configuration-bar {
    height: 40px;
    background-color: var(--v-primary-base);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  }

  .wvb-model-layers {
    position: absolute;
    right: 10px;
    bottom: 15px;
    display: flex;
    z-index: 2;
    flex-direction: column-reverse;

    .btn-layer {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .wvb-player-container {
    position: absolute;
    bottom: 100px;
    left: calc(50% - 150px);
    width: 300px;
    z-index: 2;
  }

  .forge-viewer {
    height: 100%;
    border-top: none !important;
  }

  .wvb-actions-container {
    position: absolute;
    left: 25px;
    top: 70px;
    z-index: 100;
    display: flex;
    flex-direction: column;
  }

  .forge-legend {
    position: absolute;
    top: 150px;
    right: 30px;
    padding: 10px 12px;
    overflow: hidden;
    z-index: 2;

    .legend-title {
      font-size: 20px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
    }

    .legend-list {
      overflow-y: scroll;
      height: 200px;
      list-style-type: none;
      padding: 0 5px 0 0;

      .legend-item {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 5px;

        &:hover {
          background-color: rgba(255, 255, 255, 0.55);
          cursor: pointer;
        }

        &.active {
          background-color: rgba(255, 255, 255, 0.55);
          border-right: solid 2px var(--v-primary-base);
        }

        .legend-item-title {
          flex: 1;
          margin-right: 20px;
        }

        .legend-item-color {
          width: 24px;
          height: 24px;
          margin-right: 10px;
          border-radius: 24px;
        }

        .legend-item-action {
          margin: 0 10px;
        }
      }
    }
  }
}
</style>
