<template>
  <div>
    <div class="d-flex">
      <div class="flex-grow-1">
        {{ text('phases') }}
      </div>
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-icon
            :color="colorPhasesToggle ? 'primary' : ''"
            class="ant-icon ml-2"
            @click="colorPhases()"
            v-on="on"
          >
            mdi-format-color-highlight
          </v-icon>
        </template>
        <span>{{ text('colorPhases') }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-icon
            :color="isolatedUnassignedModulesToggle ? 'primary' : ''"
            class="ant-icon ml-2"
            v-on="on"
            @click="isolateUnassignedModules"
          >
            mdi-select-group
          </v-icon>
        </template>
        <span>{{ text('isolateModules', {}) }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-icon
            :color="isolatedNonModuleObjectsToggle ? 'primary' : ''"
            class="ant-icon ml-2"
            v-on="on"
            @click="isolateNonModuleObjects"
          >
            mdi-select-inverse
          </v-icon>
        </template>
        <span>{{ text('isolateNonModules') }}</span>
      </v-tooltip>
    </div>
    <div
      v-for="phase in sortedPhases"
      :key="phase.name"
      class="phase-item d-flex ant-glass-background radius-0 pa-1 my-3 align-center"
      :class="{ 'active-phase': phase === isolatedPhase }"
      @click="isolatePhase(phase)"
    >
      <div
        class="phase-color mr-5"
        :style="'background-color:' + phase.color"
      />
      <div>
        {{ phase.name }}
      </div>
      <v-spacer />
      <v-tooltip left>
        <template #activator="{ on, attrs }">
          <v-icon
            class="ant-icon ml-2"
            @click.stop="resetPhaseSetup(phase)"
            v-on="on"
          >
            mdi-refresh
          </v-icon>
        </template>
        <span />
        {{ $t('modules.wvb.resetPhase', { phase: phase.name }) }}
      </v-tooltip>
      <v-tooltip left>
        <template #activator="{ on, attrs }">
          <v-icon
            :color="wvbSelectedPhase === phase ? 'primary' : ''"
            class="ant-icon mx-2"
            @click.stop="configurePhase(phase)"
            v-on="on"
            >mdi-cog-transfer</v-icon
          >
        </template>
        <span>{{ $t('modules.wvb.configure', { mode: phase.name }) }}</span>
      </v-tooltip>
    </div>
    <delete-dialog
      :dialog="phaseItem.hasOwnProperty('id')"
      :title="`Are you sure you want to reset ${phaseItem.name}?`"
      @closeDialog="closePhaseDialogs"
      @deleteAction="resetPhase"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DeleteDialog from '@/components/DeleteDialog';
import {
  getObjectsWithMappingLevel,
  hexToVector4,
} from '@/components/Modules/Daiwa-House-Modular-Europe/utils/DHME+utils';

export default {
  name: 'WVBPhasesSidebar',
  components: { DeleteDialog },
  data: () => {
    return {
      colorPhasesToggle: false,
      isolatedPhase: undefined,
      isolatedUnassignedModulesToggle: false,
      isolatedNonModuleObjectsToggle: false,

      phaseItem: {},
      configurationModeListener: undefined,
    };
  },
  computed: {
    ...mapGetters([
      'wvbPhases',
      'wvbModules',
      'wvbNonModules',
      'wvbSelectedPhase',
      'wvbModelMapping',
    ]),

    forgeViewer() {
      return this.$parent.$parent.$refs['wvb-viewer'].$refs['forge-viewer']
        .viewerService.Viewer3D;
    },
    phaseModelObjects() {
      return this.$parent.$parent.$refs['wvb-viewer'].modelObjects;
    },
    sortedPhases() {
      return [...this.wvbPhases].sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  methods: {
    configurePhase(phase) {
      if (this.wvbSelectedPhase === phase) {
        this.$store.dispatch('resetPhase');
        this.$parent.$parent.$refs['wvb-viewer'].configurationMode = false;
        this.$parent.$parent.$refs[
          'wvb-viewer'
        ].unloadEventAndEnableDefaultSelectionEvent();
        document.removeEventListener('keydown', this.closeConfigurePhaseMode);
      } else {
        this.$parent.$parent.$refs['wvb-viewer'].configurationMode = true;
        this.$parent.$parent.$refs['wvb-viewer'].configurationMessage =
          `Configuring ${phase.name}`;
        this.$store.dispatch('setPhase', phase);
        this.isolatedPhase = undefined;
        this.$parent.$parent.$refs['wvb-viewer'].loadSelectionEventPhases();
        document.addEventListener('keydown', this.closeConfigurePhaseMode);
      }
    },
    closeConfigurePhaseMode(event) {
      if (event.key === 'Escape') {
        //do something
        this.$store.dispatch('resetPhase');
        this.$parent.$parent.$refs['wvb-viewer'].configurationMode = false;
        this.$parent.$parent.$refs[
          'wvb-viewer'
        ].unloadEventAndEnableDefaultSelectionEvent();
        document.removeEventListener('keydown', this.closeConfigurePhaseMode);
      }
    },
    resetPhaseSetup(phase) {
      this.phaseItem = Object.assign({}, phase);
    },
    resetPhase() {
      let records = [...this.wvbModules].filter(
        (record) => record.phase === this.phaseItem.id
      );

      records.map((record) => {
        record.phase = null;
        record.placement_sequence = null;
        return record;
      });

      let data = records.map(
        ({ id, module_id, phase, phase_number, placement_sequence }) => ({
          id,
          module_id,
          phase,
          placement_sequence,
        })
      );

      this.$store.dispatch('importWvbModules', data).then(() => {
        let records = [...this.wvbNonModules].filter(
          (record) => record.phase === this.phaseItem.id
        );

        records.map((record) => {
          record.phase = null;
          return record;
        });

        let data = records.map(({ id, non_module_id, phase }) => ({
          id,
          non_module_id,
          phase,
        }));

        this.$store.dispatch('importWvbNonModules', data).then(() => {
          this.$store.commit('showNotification', {
            color: 'success',
            content: this.text('notifications.successResetPhase', {
              phaseName: phaseItem.name,
            }),
          });
          this.closePhaseDialogs();

          this.$parent.$parent.$refs['wvb-viewer'].$refs[
            'forge-viewer'
          ].viewerService.clearSearch();

          this.colorPhasesToggle = false;
          this.isolatedPhase = undefined;
          this.isolatedUnassignedModulesToggle = false;
          this.isolatedNonModuleObjectsToggle = false;
        });
      });
    },
    closePhaseDialogs() {
      this.phaseItem = Object.assign({}, {});
    },
    colorPhases() {
      this.colorPhasesToggle = !this.colorPhasesToggle;
      this.$parent.$parent.$refs['wvb-viewer'].colorTypesToggle = false;

      const moduleIdMappingLevels = this.wvbModelMapping.module_id.split('.');

      if (this.colorPhasesToggle) {
        this.forgeViewer.clearThemingColors();

        this.sortedPhases.forEach((phase) => {
          let modulesInPhase = this.wvbModules.filter(
            (record) => record.phase === phase.id
          );
          let nonModulesInPhase = this.wvbNonModules.filter(
            (record) => record.phase === phase.id
          );

          let moduleObjects = getObjectsWithMappingLevel(
            this.phaseModelObjects,
            moduleIdMappingLevels
          ).filter(
            (object) =>
              modulesInPhase.findIndex(
                (module) =>
                  moduleIdMappingLevels.reduce(
                    (o, i) => o[i],
                    object.properties
                  ) === module.module_id
              ) !== -1
          );
          let nonModuleObjects = getObjectsWithMappingLevel(
            this.phaseModelObjects,
            moduleIdMappingLevels
          ).filter(
            (object) =>
              nonModulesInPhase.findIndex(
                (module) =>
                  moduleIdMappingLevels.reduce(
                    (o, i) => o[i],
                    object.properties
                  ) === module.non_module_id
              ) !== -1
          );
          let allObjects = [...moduleObjects, ...nonModuleObjects];

          let color = hexToVector4(phase.color);

          let objectIds = this.$parent.$parent.$refs['wvb-viewer'].$refs[
            'forge-viewer'
          ].mapExternalIdsToObjectIds(
            allObjects.map((object) => object.externalId)
          );
          objectIds.forEach((id) => {
            this.forgeViewer.setThemingColor(id, color, null, true);
          });
        });
      } else {
        this.forgeViewer.clearThemingColors();
      }
    },
    isolatePhase(phase) {
      if (phase === this.isolatedPhase) {
        this.forgeViewer.showAll();
        this.isolatedPhase = undefined;
      } else {
        this.isolatedPhase = phase;
        const moduleIdMappingLevels = this.wvbModelMapping.module_id.split('.');
        let modules = this.wvbModules.filter(
          (module) => module.phase === phase.id
        );

        let objects = getObjectsWithMappingLevel(
          this.phaseModelObjects,
          moduleIdMappingLevels
        ).filter(
          (object) =>
            modules.findIndex(
              (module) =>
                moduleIdMappingLevels.reduce(
                  (o, i) => o[i],
                  object.properties
                ) === module.module_id
            ) !== -1
        );

        if (objects.length > 0) {
          let objectIds = this.$parent.$parent.$refs['wvb-viewer'].$refs[
            'forge-viewer'
          ].mapExternalIdsToObjectIds(
            objects.map((object) => object.externalId)
          );

          this.forgeViewer.isolate(objectIds);
          this.forgeViewer.fitToView(objectIds);
        } else {
          this.forgeViewer.hideAll();
        }
      }
    },
    isolateUnassignedModules() {
      this.isolatedUnassignedModulesToggle =
        !this.isolatedUnassignedModulesToggle;

      if (this.isolatedUnassignedModulesToggle) {
        this.isolatedPhase = undefined;
        this.isolatedNonModuleObjectsToggle = false;
        const moduleIdMappingLevels = this.wvbModelMapping.module_id.split('.');

        let modules = this.wvbModules.filter((module) => module.phase);
        let objects = getObjectsWithMappingLevel(
          this.phaseModelObjects,
          moduleIdMappingLevels
        ).filter(
          (object) =>
            modules.findIndex(
              (module) =>
                moduleIdMappingLevels.reduce(
                  (o, i) => o[i],
                  object.properties
                ) === module.module_id
            ) !== -1
        );

        if (objects.length > 0) {
          let objectIds = this.$parent.$parent.$refs['wvb-viewer'].$refs[
            'forge-viewer'
          ].mapExternalIdsToObjectIds(
            objects.map((object) => object.externalId)
          );
          this.forgeViewer.hide(objectIds);
        }
      } else {
        this.forgeViewer.showAll();
      }
    },
    isolateNonModuleObjects() {
      this.isolatedNonModuleObjectsToggle =
        !this.isolatedNonModuleObjectsToggle;

      if (this.isolatedNonModuleObjectsToggle) {
        this.isolatedPhase = undefined;
        this.isolatedUnassignedModulesToggle = false;
        const moduleIdMappingLevels = this.wvbModelMapping.module_id.split('.');

        const objects = getObjectsWithMappingLevel(
          this.phaseModelObjects,
          moduleIdMappingLevels
        ).filter((object) => {
          const value = moduleIdMappingLevels.reduce(
            (o, i) => o[i],
            object.properties
          );
          return value && value.startsWith('NonModuleObject;');
        });

        if (objects.length > 0) {
          let objectIds = this.$parent.$parent.$refs['wvb-viewer'].$refs[
            'forge-viewer'
          ].mapExternalIdsToObjectIds(
            objects.map((object) => object.externalId)
          );
          this.forgeViewer.isolate(objectIds);
          this.forgeViewer.fitToView(objectIds);
        }
      } else {
        this.forgeViewer.showAll();
      }
    },
    text() {
      const [path, ...params] = arguments;
      return this.$t(`modules.wvb.${path}`, params);
    },
  },
};
</script>

<style scoped lang="scss">
.phase-color {
  width: 70px;
  height: 30px;
  border-radius: 5px;
}

.phase-item {
  cursor: pointer;
  transition: 200ms;

  &:hover {
    box-shadow:
      rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  }
}

.active-phase {
  border: solid 2px var(--v-primary-base);
}
</style>
