<template>
  <div class="ant-project-toolbar">
    <template v-if="title">
      <span class="ant-toolbar-header">{{ title }}</span>
      <v-divider class="mx-2" inset vertical />
    </template>
    <div class="d-flex flex-grow-1 align-center justify-between full-height">
      <portal-target
        class="d-flex flex-grow-1 align-center full-height"
        :name="`${toolbarPrefix}ant-toolbar-left`"
        multiple
      />
      <portal-target
        class="d-flex align-center full-height"
        :name="`${toolbarPrefix}ant-toolbar-right`"
        multiple
      />
    </div>
  </div>
</template>

<script>
import { PortalTarget } from 'portal-vue';

export default {
  name: 'AntToolbarContainer',
  components: { PortalTarget },
  props: {
    title: {
      type: String,
      default: '',
    },
    antToolbarPrefix: {
      type: String,
      default: '',
    },
  },
  computed: {
    toolbarPrefix() {
      if (!this.antToolbarPrefix) return '';
      return `${this.antToolbarPrefix}-`;
    },
  },
};
</script>

<style lang="scss">
.ant-project-toolbar {
  flex: 1 0 auto;
  height: 46px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(8px);
  padding: 2px 10px;
  border-bottom: solid 1px lightgray;

  .ant-toolbar-header {
    font-size: 14px;
    color: #6b6b6b;
  }
}
</style>
